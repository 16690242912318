export function maskCpf(value: string) {
	value = value.replace(/[^0-9]/g, '').substring(0, 11);

	if (value.length > 3) {
		value = [value.slice(0, 3), '.', value.slice(3)].join('');
	}

	if (value.length > 7) {
		value = [value.slice(0, 7), '.', value.slice(7)].join('');
	}

	if (value.length > 11) {
		value = [value.slice(0, 11), '-', value.slice(11)].join('');
	}

	return value;
}

export function maskCnpj(value: string) {
	let strValor = limitTo(clearValue(value), 14);

	return strValor
		.replace(/^(\d{2})(\d)/, '$1.$2')
		.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
		.replace(/\.(\d{3})(\d)/, '.$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2');
}

export function maskCellphone(value: string) {
	let strValor = limitTo(clearValue(value), 11);

	return strValor
		.replace(/\D/g, '')
		.replace(/^(\d{2})(\d)/g, '($1) $2')
		.replace(/(\d)(\d{4})$/, '$1-$2');
}

export const clearValue = function (value: any) {
	return value.replace(/\D/g, '').replaceAll(' ', '');
};

const limitTo = function (value: any, length: any) {
	return value.substr(0, length);
};

export const formatInputNumber = (input: string) => {
	return input.replace(/[^0-9]/g, '');
};

export const maskCep = (zipCode: string) => {
	return `${zipCode.substring(0, 5)}-${zipCode.substring(5, 9)}`;
};

export const maskCreditCard = (value: string) => {
	let strValor = value;
	let strTemp = strValor;
	let v = strTemp;

	if (strTemp.match(/^[0-9]/) && strTemp.length <= 19) {
		// é numero ?

		v = v.replace(/\D/g, '');
		v = v.replace(/^(\d{4})(\d)/g, '$1 $2');
		v = v.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
		v = v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
		return v;
	} else {
		return strValor.substr(0, strValor.length - 1);
	}
};

export function maskYearAndMonth(value: string) {
	return value
		.replace(/^([1-9]\/|[2-9])$/g, '0$1/')
		.replace(/^(0[1-9]|1[0-2])$/g, '$1/')
		.replace(/^([0-1])([3-9])$/g, '0$1/$2')
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
		.replace(/^([0]+)\/|[0]+$/g, '0')
		.replace(/[^\d\/]|^[\/]*$/g, '')
		.replace(/\/\//g, '/');
}
