import {CARD_BORDERRADIUS} from '../../constants/ui/borders';
import {Card, useMediaQuery, useTheme} from '@mui/material';
import React from 'react';

interface IProps {
	children: any;
	sx?: any;
}

export default function CardPayment(props: IProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Card
			variant="elevation"
			elevation={isMobile ? 0 : 1}
			sx={theme => ({
				p: 6,
				// width: '100%',
				borderRadius: CARD_BORDERRADIUS,
				margin: '2px',
				[theme.breakpoints.down('md')]: {
					p: 2,
				},
				[theme.breakpoints.down('sm')]: {
					p: 0,
				},
				...props.sx,
			})}
		>
			{props.children}
		</Card>
	);
}
