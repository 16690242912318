import {Divider, Grid, List, ListItem, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {PAYMENT_OPTIONS} from 'constants/database_ids';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import api from 'shared/api';
import {usePaymentDataState} from 'shared/contexts/paymentDataContext';
import {IParcelsResp} from 'shared/interfaces/requests/parcels';
import CardPayment from '../../../../components/CardPayment/CardPayment';
import {useSessionContext} from '../../../../shared/contexts/sessionContext';
import {ICart} from '../../../../shared/interfaces/iCart';
import PaymentsMethods, {PROD_MASTERMIND, PROD_MASTERMIND_VALUE} from './paymentsMethods';

interface IProps {}

export default function Step3({}: IProps) {
	const {session} = useSessionContext();
	const {paymentData, setPaymentData} = usePaymentDataState();

	const ctrls = useFormContext();
	const {watch} = ctrls;

	const cart: ICart = session.cart!;
	const theme = useTheme();

	useEffect(() => {
		if (paymentData.parcels.length === 0) {
			api
				.post<{}, IParcelsResp>('getParcels', {})
				.then(({data}) => {
					setPaymentData({
						...paymentData,
						parcels: data.data.parcels,
					});
				})
				.catch(e => {
					// console.log(e)
				})
				.finally(() => {
					// setBackdropVisibility(false);
				});
		}
	}, []);

	const cartValueWatched = watch('tipoPagamento');

	return (
		<Box>
			<CardPayment
				sx={{
					[theme.breakpoints.down('sm')]: {
						boxShadow: 'none',
						padding: '0px',
					},
				}}
			>
				<Grid container pt={2} spacing={4}>
					<Grid item xs={12} sm={7} pt={2}>
						<Typography mb={2} sx={{color: 'text.secondary'}}>
							Escolha sua forma de pagamento e Finalize sua compra
						</Typography>
						<PaymentsMethods />
					</Grid>
					<Grid item xs={12} sm={5} pt={2}>
						<Typography mb={2} sx={{color: 'text.secondary'}}>
							Itens que você está adquirindo
						</Typography>
						<Divider sx={{mt: 2}} />
						<Box>
							<List sx={{width: '100%'}}>
								{Object.values(cart.produtos).map(p => (
									<Box key={p.idProduto}>
										<ListItem>
											<Typography variant={'h5'}>
												{p.nome} | X{p.quantidade}
											</Typography>
										</ListItem>
										<Divider sx={{my: '5px'}} />
									</Box>
								))}
							</List>
							<Typography mt={-1} textAlign={'end'} variant={'h4'} fontWeight={'bold'}>
								Total:{' '}
								{(session.cart!.produtos[PROD_MASTERMIND] !== undefined && cartValueWatched === PAYMENT_OPTIONS.PIX
									? session.cart!.produtos[PROD_MASTERMIND].quantidade * PROD_MASTERMIND_VALUE
									: cart.total
								).toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</CardPayment>
		</Box>
	);
}
