import Box from '@mui/material/Box';
import React from 'react';
import {WHITE_MAIN} from '../../constants/ui/colors';
import BtnWhatsapp from '../BtnWhatsapp/BtnWhatsapp';

export default function PaymentBg({children}: any) {
	return (
		<Box
			pt={2}
			sx={{
				minHeight: '77vh',
				backgroundColor: WHITE_MAIN,
			}}
		>
			{children}

			<Box>
				<BtnWhatsapp />
			</Box>
		</Box>
	);
}
