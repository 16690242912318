import {Grid, TextField} from '@mui/material';
import React, {useState} from 'react';
import {clearValue, maskCep} from '../../shared/helpers/mask';
import {getCepLocation, ICepResponse} from '../../shared/requests/cep';
import {Controller, useFormContext} from 'react-hook-form';
import {isCep, required} from '../../shared/helpers/reactFormHookValidation';
import {TToastState} from '../../shared/providers/ToastProvider';
import {EnumToast} from '../../shared/enums/toast';
import ToastContainer from '../ToastContainer/ToastContainer';
import Box from '@mui/material/Box';
import {useBackdropState} from '../../shared/contexts/backdropContext';
import {IErrorResponse} from '../../shared/api';
import {IFieldsAddress} from '../../shared/interfaces/fieldsAddress';

interface IAddressFields {
	fieldName: string;
}

const AddressFields = (props: IAddressFields) => {
	const {fieldName} = props;

	const [toast, setToast] = useState<TToastState>(null);
	const {setBackdropVisibility} = useBackdropState();

	const {
		setValue,
		control,
		formState: {errors},
		register,
	} = useFormContext<{[fieldName: string]: IFieldsAddress}>();

	const handleGetCep = (cep: string) => {
		setBackdropVisibility(true);
		getCepLocation(cep)
			.then((res: any) => {
				if (res.data.erro === undefined) {
					const data = res.data as ICepResponse;

					setValue(`${fieldName}.estado`, data.uf);
					setValue(`${fieldName}.cidade`, data.localidade);
					setValue(`${fieldName}.bairro`, data.bairro);
					setValue(`${fieldName}.endereco`, data.logradouro);
					setValue(`${fieldName}.complemento`, data.complemento);
				} else {
					setToast({
						text: 'Não encontramos seu cep. Verifique os números digitados',
						type: EnumToast.ERROR,
					});
				}
			})
			.catch(({response: {data}}: IErrorResponse) => {
				// console.log(data)
				setToast({
					text: 'Não encontramos seu cep. Verifique os números digitados',
					type: EnumToast.ERROR,
				});
			})
			.finally(() => setBackdropVisibility(false));
	};

	return (
		<>
			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.cep`}
					rules={{
						...isCep,
						required: required(),
					}}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Cep'}
							error={Boolean(errors[fieldName]?.cep)}
							helperText={errors[fieldName]?.cep?.message}
							{...field}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const cep = clearValue(e.target.value);
								setValue(`${fieldName}.cep`, maskCep(cep));

								if (cep.length === 8) {
									handleGetCep(cep);
								}
							}}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.cidade`}
					rules={{
						required: required(),
					}}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Cidade'}
							error={Boolean(errors[fieldName]?.cidade)}
							helperText={errors[fieldName]?.cidade?.message}
							{...field}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.bairro`}
					rules={{
						required: required(),
					}}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Bairro'}
							error={Boolean(errors[fieldName]?.bairro)}
							helperText={errors[fieldName]?.bairro?.message}
							{...field}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.endereco`}
					rules={{
						required: required(),
					}}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Endereço'}
							error={Boolean(errors[fieldName]?.endereco)}
							helperText={errors[fieldName]?.endereco?.message}
							{...field}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.numero`}
					rules={{
						required: required(),
					}}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Número'}
							error={Boolean(errors[fieldName]?.numero)}
							helperText={errors[fieldName]?.numero?.message}
							{...field}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Controller
					control={control}
					name={`${fieldName}.complemento`}
					render={({field}) => (
						<TextField
							fullWidth
							size={'small'}
							label={'Complemento'}
							error={Boolean(errors[fieldName]?.complemento)}
							helperText={errors[fieldName]?.complemento?.message}
							{...field}
						/>
					)}
				/>
			</Grid>
			<Box>
				<ToastContainer toast={toast} setToast={setToast} />
			</Box>

			<input {...register(`${fieldName}.estado`)} type={'hidden'} />
		</>
	);
};

export default AddressFields;
