import api, {IErrorResponse} from '../../../../shared/api';
import {ICoursesLoginReq, ICoursesLoginResp} from '../../../../shared/interfaces/requests/courses';
import {EnumToast} from '../../../../shared/enums/toast';
import React, {Dispatch, useEffect, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button, Grid, TextField} from '@mui/material';
import {isCpf, required} from '../../../../shared/helpers/reactFormHookValidation';
import {maskCpf} from '../../../../shared/helpers/mask';
import {useBackdropState} from '../../../../shared/contexts/backdropContext';
import {useSessionContext} from '../../../../shared/contexts/sessionContext';
import ToastContainer from '../../../../components/ToastContainer/ToastContainer';
import {TToastState} from '../../../../shared/providers/ToastProvider';
import {ICoursePayment} from '../../../../shared/interfaces/ICoursePayment';
import {LS} from '../../../../shared/enums/localstorage';

interface IProps {
	buyerCpf: string;
	setProducts: Dispatch<ICoursePayment[]>;
}

const SectionBuyer = ({buyerCpf, setProducts}: IProps) => {
	const {setBackdropVisibility} = useBackdropState();
	const [buyerFinded, setBuyerFinded] = useState<boolean>(false);
	const {session, setSession} = useSessionContext();
	const [toast, setToast] = useState<TToastState>(null);

	const ctrlsForm1 = useFormContext();
	const {
		register,
		getValues,
		handleSubmit,
		control,
		formState: {errors},
		setValue,
		unregister,
	} = ctrlsForm1;

	useEffect(() => {
		buyerCpf.length && onGetPeopleProducts({cpf: buyerCpf});
	}, [buyerCpf]);

	const onGetPeopleProducts = (data: any) => {
		buyerFinded && setBuyerFinded(false);
		setBackdropVisibility(true);

		api
			.post<ICoursesLoginReq, ICoursesLoginResp>('loginAndCheck', {
				cpf: data.cpf,
				token: session.session?.token,
			})
			.then(({data}) => {
				if (data.data.userFinded) {
					localStorage.setItem(LS.CARTTOKEN, data.data.cartToken);

					setBuyerFinded(true);

					if (data.data.courses.length) {
						setProducts(data.data.courses);
					} else {
						setToast({
							type: EnumToast.ERROR,
							text: 'Nenhum curso adquirido encontrado',
						});
					}
				}
			})
			.catch(({response: {data}}: IErrorResponse) => {
				setToast({
					type: EnumToast.ERROR,
					text: data.message,
				});
			})
			.finally(() => {
				setBackdropVisibility(false);
			});
	};

	useWatch({
		control: ctrlsForm1.control,
		name: 'cpf',
		// name: "nome.tipoPessoa",
	});

	return (
		<Box>
			<Typography variant={'h4'} mb={2}>
				CPF de quem comprou
			</Typography>
			<form onSubmit={ctrlsForm1.handleSubmit(onGetPeopleProducts)}>
				<Grid container spacing={2} mb={2}>
					<Grid item xs={12} md={6}>
						<Controller
							control={ctrlsForm1.control}
							name="cpf"
							rules={{
								...isCpf,
								required: required(),
							}}
							render={({field}) => (
								<TextField
									fullWidth
									size={'small'}
									id="cpf"
									{...field}
									label="CPF - Somente números"
									sx={{backgroundColor: 'white'}}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										ctrlsForm1.setValue('cpf', maskCpf(e.target.value));
									}}
									error={Boolean(ctrlsForm1.formState.errors.cpf)}
									helperText={ctrlsForm1.formState.errors.cpf && `O CPF digitado é inválido `}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Button variant="contained" disabled={!(ctrlsForm1.getValues('cpf').length >= 14)} size={'medium'} type="submit">
								Buscar
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<ToastContainer toast={toast} setToast={setToast} />
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default SectionBuyer;
