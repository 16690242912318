import React, {createContext, Dispatch, useState} from 'react';
import {EnumToast} from '../enums/toast';
import {Alert, Snackbar} from '@mui/material';
import {DEFAULT_TOAST_DURATION, ToastOptions} from '../../constants/toast';
import Typography from '@mui/material/Typography';

export interface IToastData {
	type: EnumToast;
	text?: string;
}

export type TToastState = IToastData | null;

export interface IContext {
	toast: TToastState;
	setToast: Dispatch<IToastData>;
}

export const ToastContext = createContext<IContext>({
	toast: null,
	setToast: (toast: TToastState) => {},
});

export default function ToastProvider(props: {children: any}) {
	const [toastState, _setToastState] = useState<TToastState>(null);
	const setToast = (toast: TToastState) => {
		// console.log('Setou', toast)
		_setToastState(
			toast === null
				? null
				: {
						type: toast.type,
						text: toast.text ?? ToastOptions[toast.type].text,
					}
		);
	};

	const contextValue = {
		toast: toastState,
		setToast: setToast,
	};

	const handleClose = () => {
		setToast(null);
	};

	return (
		<ToastContext.Provider value={contextValue}>
			{toastState ? (
				<Snackbar
					anchorOrigin={{
						horizontal: 'center',
						vertical: 'bottom',
					}}
					autoHideDuration={DEFAULT_TOAST_DURATION}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity={ToastOptions[toastState.type].severity}>
						<Typography variant={'h4'}>{toastState.text}</Typography>
					</Alert>
				</Snackbar>
			) : null}
			{props.children}
		</ToastContext.Provider>
	);
}

export const ToastConsumer = ToastProvider;
