import Payload from 'node-pix';

export const generateCopiaCola = (value: number) => {
	const PIX_KEY = '35115289000179';
	const PIX_MERCHANT_NAME = 'Grupo Txai';
	const PIX_MERCHANT_CITY = 'SAO PAULO';

	return Payload.generate({
		pixKey: PIX_KEY,
		merchant: {
			name: PIX_MERCHANT_NAME,
			city: PIX_MERCHANT_CITY,
		},
		amount: value,
	});
};
