import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';
import {MD, SM, XS} from './constants/ui/breakpoints';
import {Breakpoint, createBreakpoints} from '@mui/system';
import {BLUE_PRIMARY, DARK_MAIN, DARK_SECONDARY} from './constants/ui/colors';

const defaultTextOptions = {
	// lineHeight: '24px',
	color: DARK_MAIN,
	lineHeight: '1.625',
};

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: false;
		xl: false;
	}
}
// declare module '@mui/material/styles/createPalette' {
//   interface Palette {
//     orange: Palette['primary'];
//   }
//   interface PaletteOptions {
//     orange: PaletteOptions['primary'];
//   }
// }

const BREAKPOINTS = {
	xs: XS,
	sm: SM,
	md: MD,
};

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: BLUE_PRIMARY,
			contrastText: '#fff',
		},
		secondary: {
			main: DARK_SECONDARY,
		},
		error: {
			main: red.A400,
		},
		action: {
			disabledBackground: 'rgb(223, 223, 223)',
		},
		text: {
			primary: DARK_MAIN,
			secondary: DARK_SECONDARY,
		},
		// orange: {
		//   main: '#e0bc20',
		//   contrastText: '#fff',
		// },
	},
	typography: {
		allVariants: {},
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Montserrat',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontSize: 36,
			...defaultTextOptions,
		},
		h2: {
			fontSize: 24,
			...defaultTextOptions,
		},
		h3: {
			fontSize: 20,
			...defaultTextOptions,
		},
		h4: {
			fontSize: 16,
			...defaultTextOptions,
		},
		h5: {
			fontSize: 12,
			...defaultTextOptions,
		},
		button: {
			fontSize: 16,
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			...defaultTextOptions,
		},

		body2: {
			fontSize: 16,
			...defaultTextOptions,
		},
	},
	components: {
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		// Name of the component
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					fontSize: '16px',
					textTransform: 'none',
				},
				// outlined: {
				//   border: `1px solid ${MAIN_DARK}`,
				//   ":hover": {
				//     backgroundColor: '#0b09262b',
				//     border: '1px solid gray'
				//     ,
				//   },
				// },
				disabled: {
					// backgroundColor:
				},
			},
		},

		// .css-wo65lf-MuiPaper-root-MuiAccordion-root.Mui-expanded
		MuiAccordion: {
			styleOverrides: {
				// Name of the slot
				root: {
					margin: '0px',
					'&.Mui-expanded': {
						margin: '0px',
					},
				},
			},
		},
	},
	breakpoints: createBreakpoints({
		// Define custom breakpoint values.
		// These will apply to Material-UI components that use responsive
		// breakpoints, such as `Grid` and `Hidden`. You can also use the
		// theme breakpoint functions `up`, `down`, and `between` to create
		// media queries for these breakpoints
		values: BREAKPOINTS,
		keys: Object.keys(BREAKPOINTS) as Breakpoint[],
	}),
});

export default theme;
