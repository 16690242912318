export const CONTRACT_TEXT = `CONTRATO DE BOA CONVIVÊNCIA

Compromisso do GRUPO TXAI Entregar o Congresso de forma presencial com todos os

Entregar nota fiscal de compra;

Cuidar dos seus dados conforme os princípios da política de privacidade do Grupo TXAI;

Realizar o Certificado de conclusão para os que concluírem;

Compromisso do Aluno

Entregar 110% de comprometimento durante o Congresso.

Implementar na prática todo o conhecimento que irá adquirir no Congresso.

Estar disponível e aberto ao novo e para realizar o networking com os demais participantes.

Cumprir os horários do congresso.

Privacidade de dados.

O Grupo TXAI e o Participante do Congresso estão cientes dos termos da política de privacidade, e estão de acordo com os princípios e políticas lá determinadas.

Política de Compliance, Anticorrupção e Antissuborno

a) O Grupo TXAI e o Participante do Congresso, por si e por seus administradores, sócios, empregados, agentes, prepostos ou representantes declaram conhecer e cumprir na íntegra as disposições do Código de Compliance Anticorrupção e Antissuborno da CONTRATADA, que passa a ser parte desse contrato, com versão disponível no endereço eletrônico http://www.txaidesenvolvimento.com.br/novo/Empresa/programa-de-integridade-etica-anticorrupcao-e-compliance/4

b) As partes (GRUPO TXAI e Participante do Congresso) afirmam e entendem que é expressamente vedado receber ou entregar, uma parte à outra, recurso financeiro, favores não pertinentes ao ramo de atuação, presentes de alto valor R$, a qualquer pretexto, excetuando-se os brindes meramente institucionais e sem valor comercial.

c) As partes (GRUPO TXAI e Participante do Congresso) estão cientes de que não será tolerado, em nenhuma hipótese, qualquer prática de suborno, desvios financeiros, fraudes, desvio de conduta ética ou atos lesivos praticados uma contra a outra ou contra a Administração Pública.

d) § 3º. As partes declaram que têm conhecimento da Lei nº 12.846/13 (Lei Anticorrupção), bem como dos artigos 317 e 333 do Código Penal Brasileiro, os quais tratam da corrupção passiva e da corrupção ativa, obrigando-se a cumprir integralmente com seus dispositivos, mediante a abstenção de qualquer atividade que constitua ou possa constituir violação às Regras Anticorrupção.

e) As partes declaram-se cientes que existe um canal exclusivo e confidencial, para comunicação segura e anônima de condutas consideradas antiéticas ou que violem o Código de Compliance Anticorrupção e Antissuborno e/ou legislações vigentes, e que o relato pode ser realizado pelo e-mail denuncia@txaidesenvolvimento.com.br, pelo site https://www.txaidesenvolvimento.com.br/novo/Denuncie ou, também, por correspondência a ser encaminhada para a Avenida Salmão, nº 663, Edifício Sun Tower, sala 42, Jardim Aquárius, São José dos Campos/SP, CEP: 12.246-260 AC/ Denise Fernandes da Cruz.

Uso de Imagem

a) Durante o Congresso, o GRUPO TXAI se reserva o direito de coletar, armazenar e utilizar a sua imagem contida em postagens, foto (s) e/ou filmagens e áudios a serem coletado (s) durante a mentoria, resumidas ou não, em campanhas de caráter comercial e institucional, destinada a divulgar produtos.

b) Na qualidade de Participante do Congresso declaro para os devidos fins de direito, e para que surta seus efeitos legais e jurídicos, que tenho pleno conhecimento e expresso minha total anuência quanto a gravação em vídeo de minhas participações nas lives quando convidado, depoimentos, gravação de vídeos e demais atividades de captação de imagem e vídeo, podendo ser a gravação utilizada pelos organizadores para fins de estudo e divulgação, mídias sociais, veiculação de qualquer espécie, podendo ainda minha participação ser usada na publicação de artigos em revistas especializadas ou afins.

Relacionamento e Comunicação

a) Nossa equipe está preparada para responder suas dúvidas postadas na comunidade em até 01 (um) dia útil.

b) É estritamente proibido apresentar reclamações ou insatisfações na comunidade. Se você tem uma reclamação ou insatisfação, entre em contato com a ouvidoria: txai@txaidesenvolvimento.com.br ou pelo WhatsApp 055 99966 7868 de segunda à sexta feira das 8h às 18h.

c) Se você tem algum conhecimento que gostaria de compartilhar ou uma sugestão de conteúdo e melhoria do treinamento, mentoria ou conteúdos em geral, envie por e-mail para txai@grupotxai.com.br

d) Não é permitido publicar o seu contato pessoal ou de terceiros, e-mail ou telefone nos meios de comunicação como forma de divulgação, venda, marketing ou publicidades de conteúdos não pertinentes.

e) Não é permitido postar na COMUNIDADE qualquer assunto que não for relacionado ao Congresso.

f) A TXAI se reserva no direito de adicionar, editar ou remover regras dessa comunidade, sem qualquer aviso prévio de participantes que desrespeitarem alguma regra.

Regras Gerais

a) Não permitimos plágio ou qualquer outra forma de compartilhamento ou comercialização de conteúdo, produtos e outros elementos compartilhados dentro do grupo. Respeite a propriedade intelectual dos membros do grupo e evite eventuais problemas jurídicos;


`;
