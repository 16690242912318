import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Login from '../pages/login';
import Pagamento from '../pages/pagamento';
import Concluir from '../pages/concluir';
import Cadastro from '../pages/cadastro';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import {useSessionContext} from '../shared/contexts/sessionContext';
import {PaymentDataContextProvider} from '../shared/contexts/paymentDataContext';
import Formulario from '../pages/formulario';
import Sucesso from '../pages/formulario/sucesso';
import NotFound from '../pages/errors/notFound';
import CadastroConcluido from '../pages/cadastro/concluido';

const router = () => {
	const {session, setSession} = useSessionContext();

	let isLogged = false;

	if (session.session !== undefined && session.cart !== undefined) {
		isLogged = true;
	}

	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="/login" element={<Login />} />
			<Route path="/cadastro" element={<Cadastro />} />
			<Route path="/cadastro/concluir" element={<CadastroConcluido />} />
			<Route element={<ProtectedRoute redirectPath={'/login'} isLogged={isLogged} />}>
				<Route
					path="/pagamento"
					element={
						<PaymentDataContextProvider>
							<Pagamento />
						</PaymentDataContextProvider>
					}
				/>
			</Route>
			<Route path="/concluir" element={<Concluir />} />
			<Route path="/formulario" element={<Formulario />} />
			<Route path="/formulario/concluir" element={<Sucesso />} />
			<Route path={'*'} element={<NotFound />} />
		</Routes>
	);
};

export default router;
