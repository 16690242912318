import Box from '@mui/material/Box';
import React, {memo} from 'react';
import {FormControl, FormHelperText, Grid, MenuItem, Select, TextField} from '@mui/material';
import {clearValue, maskCellphone, maskCnpj, maskCpf} from '../../../../shared/helpers/mask';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {isCnpj, isCpf, isEmail, isPhone, maxLenght, minLenght, required} from '../../../../shared/helpers/reactFormHookValidation';
import AddressFields from '../../../../components/AddressFields/AddressFields';
import TextSecondary from '../../../../components/TextSecondary/TextSecondary';
import {IFieldsInvoice} from '../../../../shared/interfaces/fieldsInvoice';

type formType = {dadosNotaFiscal: IFieldsInvoice};

export default function FiscalNote() {
	const ctrls = useFormContext<formType>();

	const {
		register,
		getValues,
		handleSubmit,
		control,
		formState: {errors},
		setValue,
		unregister,
	} = ctrls;

	useWatch({
		control: control,
		name: 'dadosNotaFiscal.tipoPessoa',
		// name: "nome.tipoPessoa",
	});

	return (
		<Box sx={{pt: 3}}>
			<TextSecondary>Nota fiscal</TextSecondary>
			<Box mt={2}>
				<Controller
					name="dadosNotaFiscal.tipoPessoa"
					control={control}
					defaultValue={1}
					rules={{
						required: {
							message: 'Obrigatório',
							value: true,
						},
					}}
					render={({field}) => (
						<FormControl fullWidth error={Boolean(errors.dadosNotaFiscal?.tipoPessoa)}>
							<Select
								fullWidth
								size={'small'}
								label=""
								{...field}
								// onChange={e => {setValue('dadosNotaFiscal.tipoPessoa', 2)}}
							>
								<MenuItem value={1}>Para mim</MenuItem>
								<MenuItem value={2}>Para outra pessoa</MenuItem>
								<MenuItem value={3}>Para uma empresa</MenuItem>
							</Select>
							<FormHelperText>{`${errors.dadosNotaFiscal?.tipoPessoa?.message ?? ''}`}</FormHelperText>
						</FormControl>
					)}
				/>
			</Box>
			{getValues('dadosNotaFiscal.tipoPessoa') !== 1 ? <FormFields {...ctrls} /> : null}
		</Box>
	);
}

const FormFields = memo(
	(ctrls: UseFormReturn<formType>) => {
		// UseFormReturn<IPaymentData>

		const {
			setValue,
			control,
			getValues,
			formState: {errors},
		} = ctrls;

		const isEnterprise = getValues('dadosNotaFiscal.tipoPessoa') === 3;
		const nameCpfCnpj = isEnterprise ? 'CNPJ' : 'CPF';
		// console.log('Renderizou subcomponente', errors)

		return (
			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="dadosNotaFiscal.cpfCnpj"
							rules={{
								...(isEnterprise ? isCnpj : isCpf),
								required: required(),
							}}
							render={({field}) => (
								<TextField
									fullWidth
									size={'small'}
									label={nameCpfCnpj}
									error={Boolean(errors.dadosNotaFiscal?.cpfCnpj)}
									helperText={errors.dadosNotaFiscal?.cpfCnpj && `O ${nameCpfCnpj} digitado é inválido `}
									{...field}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										e.target.value = isEnterprise ? maskCnpj(e.target.value) : maskCpf(e.target.value);
										// formik.handleChange(e)
										setValue('dadosNotaFiscal.cpfCnpj', e.target.value);
									}}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="dadosNotaFiscal.nome"
							rules={{
								minLength: minLenght(2),
								maxLength: maxLenght(255, 'alguns'),
								required: required(),
							}}
							render={({field}) => (
								<TextField
									fullWidth
									size={'small'}
									label={'Nome Completo'}
									error={Boolean(errors.dadosNotaFiscal?.nome)}
									helperText={errors.dadosNotaFiscal?.nome?.message}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="dadosNotaFiscal.email"
							rules={{...isEmail, required: required()}}
							render={({field}) => (
								<TextField
									fullWidth
									size={'small'}
									label={'E-mail'}
									error={Boolean(errors.dadosNotaFiscal?.email)}
									helperText={errors.dadosNotaFiscal?.email?.message}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="dadosNotaFiscal.telefone"
							rules={{
								...isPhone,
								required: required(),
							}}
							render={({field}) => (
								<TextField
									fullWidth
									size={'small'}
									label="Whatsapp"
									error={Boolean(errors.dadosNotaFiscal?.telefone)}
									helperText={errors.dadosNotaFiscal?.telefone?.message}
									{...field}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const cellphone = clearValue(e.target.value);
										setValue(`${'dadosNotaFiscal'}.telefone`, maskCellphone(cellphone));
									}}
								/>
							)}
						/>
					</Grid>
					<AddressFields fieldName={'dadosNotaFiscal'} />
				</Grid>
			</Box>
		);
	},
	(prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty && nextProps.getValues('dadosNotaFiscal.tipoPessoa') === 1
);
