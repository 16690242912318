export const DARK_MAIN = '#202020';
export const DARK_SECONDARY = '#8A8A8A';
export const WHITE_MAIN = '#FFFFFF';
export const WHITE_FILL = '#FBFBFB';
export const STROKE_1 = '#E8E8E8';
export const STROKE_2 = '#CED4DA';
export const BLUE_PRIMARY = '#0D6EFD';
export const BLUE_SECONDARY = '#DEEBFF';
export const WHATSAPP = '#0DA313';
export const WHATSAPP_HOVER = '#1F4A4A';
