import React from 'react';

interface IProps {
	children: any;
	href: string;
}

export default function BtnIcon({children, href}: IProps) {
	return (
		<a href={href} target={'_blank'}>
			{children}
		</a>
	);
}
