import {Box, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {LS} from 'shared/enums/localstorage';
import {cartsIsEquals} from 'shared/helpers/cart';
import {scrollToElementById} from 'shared/helpers/scrool';
import {getCart} from 'shared/requests/getCart';
import CustomContainer from '../../components/Container/CustomContainer';
import PaymentButtons from '../../components/PaymentButtons/PaymentButtons';
import ToastContainer from '../../components/ToastContainer/ToastContainer';
import PaymentBg from '../../components/paymentBg';
import api, {IErrorResponse} from '../../shared/api';
import {useBackdropState} from '../../shared/contexts/backdropContext';
import {useSessionContext} from '../../shared/contexts/sessionContext';
import {EnumToast} from '../../shared/enums/toast';
import {clearValue} from '../../shared/helpers/mask';
import {ICard} from '../../shared/interfaces/ICard';
import {IFieldsInvoice} from '../../shared/interfaces/fieldsInvoice';
import {IPaymentReq, IPaymentResp} from '../../shared/interfaces/requests/payment';
import {TToastState} from '../../shared/providers/ToastProvider';
import {CartWithOutProductsMessage} from './components/CartWithoutProductsMessage';
import HeaderText from './components/HeaderText';
import Steps from './components/Steps';
import TabPanel from './components/TabPanel';
import Step1 from './sections/step1/step1';
import Step2 from './sections/step2/step2';
import Step3 from './sections/step3/step3';

export const STEPS = ['Pedido', 'Nota Fiscal', 'Formas de pagamento'];
export const STEPSMOBILE = ['Pedido', 'Nota', 'Pagamento'];

export default function Pagamento() {
	const navigate = useNavigate();
	const {session, setSession} = useSessionContext();
	const {setBackdropVisibility} = useBackdropState();

	const [form2Data, setform2Data] = useState<IForm2 | null>(null);
	const [canExecutePayment, setCanExecutePayment] = useState<boolean>(true);
	const [toast, setToast] = useState<TToastState>(null);

	useEffect(() => {
		if (!session || !session.session || !session.session.user) {
			setSession({
				cart: undefined,
				session: undefined,
			});
			navigate('/login', {
				replace: true,
			});
		}
	}, [session]);

	const ctrlsForm2 = useForm<IForm2>({
		shouldFocusError: true,
		defaultValues: form2DefaultValues,
	});

	const ctrlsForm3 = useForm<IForm3>({
		shouldFocusError: true,
		defaultValues: form3DefaultValues,
	});

	const onSubmit = (data: IForm3) => {
		const requestData: IPaymentReq = {
			total: session.cart!.total,
			payment: {
				info: {
					type: parseInt(data.tipoPagamento),
				},
				data: [data.dadosCartao1, data.dadosCartao2],
				dadosNotaFiscal: {
					...form2Data!.dadosNotaFiscal,
					cep: clearValue(form2Data!.dadosNotaFiscal.cep),
					cpfCnpj: clearValue(form2Data!.dadosNotaFiscal.cpfCnpj),
					telefone: clearValue(form2Data!.dadosNotaFiscal.telefone),
				},
			},
			extra: [],
		};

		setBackdropVisibility(true);
		getCart({})
			.then(res => {
				if (!cartsIsEquals(session.cart!, res.data.data)) {
					setBackdropVisibility(false);
					setSession({
						cart: res.data.data,
						session: {
							...session.session!,
						},
					});
					scrollToElementById('ToastWrapper');
					setToast({
						type: EnumToast.SUCCESS,
						text: 'Houve alterações em seu carrinho. Verifique as informações antes de prosseguir para o pagamento.',
					});
				} else {
					sendPayment(requestData);
				}
			})
			.catch(() => {
				setBackdropVisibility(false);
				localStorage.removeItem(LS.CARTTOKEN);
				scrollToElementById('ToastWrapper');
				api.post('logout', {}).finally(() => {
					setCanExecutePayment(false);
					setToast({
						type: EnumToast.ERROR,
						text: 'Não entontramos seu carrinho. Vamos redirecionar você para a página de login.',
					});

					setTimeout(() => {
						setSession({
							cart: undefined,
							session: undefined,
						});
						navigate('/login');
					}, 4000);
				});
			});
	};

	const sendPayment = (requestData: IPaymentReq) => {
		api
			.post<IPaymentReq, IPaymentResp>('executePayment', requestData)
			.then(({data}) => {
				if (data.data.emailsResult.status) {
					setSession({
						cart: data.data.carrinho,
						session: {
							...session.session!,
						},
					});
					navigate('/concluir', {
						state: {
							...data.data,
						},
					});
				} else {
					scrollToElementById('ToastWrapper');
					setToast({
						type: EnumToast.ERROR,
						text: 'Oops, ocorreu um problema desconhecido. Por favor, entre em contato conosco via chat.',
					});
				}
				setBackdropVisibility(false);
			})
			.catch(({response: {data}}: IErrorResponse) => {
				setBackdropVisibility(false);
				scrollToElementById('ToastWrapper');
				setToast({
					type: EnumToast.ERROR,
					text: data.message != undefined ? data.message : 'Erro desconhecido. Por favor, entre em contato conosco',
				});
			})
			.finally(() => {
				setBackdropVisibility(false);
			});
	};

	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);

	const handleSubmitForm2 = (data: IForm2) => {
		setform2Data(data);
		handleNext();
	};

	const handleNext = () => {
		if (activeStep !== STEPS.length - 1) {
			setActiveStep(prevActiveStep => prevActiveStep + 1);
		}
	};

	useEffect(() => {
		if (activeStep === 2) {
			setCanExecutePayment(true);
		}
	}, [activeStep]);

	const numberOfProducts = session.cart && session.cart.produtos && Object.keys(session.cart!.produtos).length;

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Realize seu pagamento com segurança</title>
			</Helmet>
			<CustomContainer sx={{py: 4}}>
				<HeaderText userName={session.session!.user.nome} />
				<Steps activeStep={activeStep} />
				<Box sx={{minHeight: 54}} id={'ToastWrapper'}>
					<ToastContainer toast={toast} setToast={setToast} />
				</Box>
				<SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep}>
					<TabPanel value={activeStep} index={0} dir={theme.direction}>
						<Step1 />
						{!numberOfProducts ? <CartWithOutProductsMessage /> : null}
						<PaymentButtons disabled={!numberOfProducts} activeStep={activeStep} setActiveStep={setActiveStep} />
					</TabPanel>
					<TabPanel value={activeStep} index={1} dir={theme.direction}>
						<FormProvider {...ctrlsForm2}>
							<form onSubmit={ctrlsForm2.handleSubmit(handleSubmitForm2)}>
								<Step2 />
								<PaymentButtons activeStep={activeStep} setActiveStep={setActiveStep} />
							</form>
						</FormProvider>
					</TabPanel>
					<TabPanel value={activeStep} index={2} dir={theme.direction}>
						<FormProvider {...ctrlsForm3}>
							<form onSubmit={ctrlsForm3.handleSubmit(onSubmit)}>
								<Step3 />
								<PaymentButtons activeStep={activeStep} setActiveStep={setActiveStep} disabled={!canExecutePayment} />
							</form>
						</FormProvider>
					</TabPanel>
				</SwipeableViews>
			</CustomContainer>
		</PaymentBg>
	);
}

export function getFormHookProps(errors: any) {
	return {
		error: Boolean(errors.dadosNotaFiscal?.cpfCnpj),
		helperText: errors.dadosNotaFiscal?.cpfCnpj?.message,
	};
}

interface IForm2 {
	dadosNotaFiscal: IFieldsInvoice;
	termos: boolean;
}

interface IForm3 {
	tipoPagamento: string;
	dadosCartao1: ICard;
	dadosCartao2: ICard;
}

export interface IPaymentData {
	dadosNotaFiscal: IFieldsInvoice;
	coupon: string;
	tipoPagamento: number;
	dadosCartao1: {
		numero: '';
		validadeCartao: '';
		numeroParcelas: number;
		codigoSeguranca: '';
	};
	dadosCartao2: {
		numero: '';
		validadeCartao: '';
		numeroParcelas: number;
		codigoSeguranca: '';
	};
}

const form2DefaultValues: IForm2 = {
	dadosNotaFiscal: {
		tipoPessoa: 1,
		cpfCnpj: '',
		nome: '',
		email: '',
		cep: '',
		endereco: '',
		bairro: '',
		telefone: '',
		cidade: '',
		numero: '',
		complemento: '',
		estado: '',
	},
	termos: false,
};

const form3DefaultValues: IForm3 = {
	dadosCartao1: {
		numero: '',
		validadeCartao: '',
		numeroParcelas: 1,
		codigoSeguranca: '',
	},
	dadosCartao2: {
		numero: '',
		validadeCartao: '',
		numeroParcelas: 1,
		codigoSeguranca: '',
	},
	tipoPagamento: '',
};
