import {Add, Remove} from '@mui/icons-material';
import {Chip, Divider, Grid, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {PRODUCT_IMG_DEFAULT} from 'constants/appconfig';
import React, {useContext} from 'react';
import CardPayment from '../../../../components/CardPayment/CardPayment';
import TextSecondary from '../../../../components/TextSecondary/TextSecondary';
import {DARK_MAIN} from '../../../../constants/ui/colors';
import api, {IErrorResponse} from '../../../../shared/api';
import {useBackdropState} from '../../../../shared/contexts/backdropContext';
import {useSessionContext} from '../../../../shared/contexts/sessionContext';
import {EnumToast} from '../../../../shared/enums/toast';
import {ToggleLetters} from '../../../../shared/helpers/string';
import {IProduct} from '../../../../shared/interfaces/IProduct';
import {ICart} from '../../../../shared/interfaces/iCart';
import {IProductQuantityReq} from '../../../../shared/interfaces/requests/productquantity';
import {ToastContext} from '../../../../shared/providers/ToastProvider';
import DiscountCoupon from './discountCoupon';

// type TChangeAmount =

interface IProps {}

type THandleChangeAmount = (quantidade: number, idProdutoCarrinho: string, type: 'add' | 'remove') => void;

export default function Step1({}: IProps) {
	const {setBackdropVisibility} = useBackdropState();
	const {session, setSession} = useSessionContext();

	const {setToast} = useContext(ToastContext);

	const handleChangeAmount: THandleChangeAmount = (quantidade, idProdutoCarrinho, type) => {
		if (!(type === 'remove' && !(quantidade - 1))) {
			setBackdropVisibility(true);

			api
				.post<IProductQuantityReq, ICart>('changeQuantity', {
					idProdutoCarrinho: parseInt(idProdutoCarrinho),
					quantidade: quantidade,
					action: type,
				})
				.then(({data}) => {
					if (data.success) {
						setSession({
							...session,
							cart: data.data,
						});
					} else {
						// console.log(data)
						setToast({
							type: EnumToast.ERROR,
							text: data.message,
						});
					}
				})
				.catch(({response: {data}}: IErrorResponse) => {
					setToast({
						type: EnumToast.ERROR,
						text: data.message,
					});

					if (data.code === 2050) {
					}
				})
				.finally(() => setBackdropVisibility(false));
		}
	};

	// console.log('renderizou pagina de pagamento')

	const cart: ICart = session.cart!;

	return (
		<Box padding={0}>
			<CardPayment>
				<TextSecondary sx={{mb: 2}}>Resumo do pedido</TextSecondary>
				<Box>
					{Object.values(cart.produtos).map(p => (
						<ProductItem key={p.idProduto} product={p} handleChangeAmount={handleChangeAmount} />
					))}
				</Box>
				<Grid container pt={2}>
					<Grid item xs={12} sm={6} pt={2}>
						<DiscountCoupon />
					</Grid>
					<Grid item xs={12} sm={6} pt={2}>
						<Box display={'flex'} width={'100%'} justifyContent={'end'}>
							<Box mt={'auto'} mx={2}>
								<Typography variant={'h5'}>
									Subtotal:{' '}
									{(cart.total + cart.totalDesconto).toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</Typography>
								<Typography my={1} variant={'h5'}>
									Descontos: -
									{cart.totalDesconto.toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</Typography>
								<Typography variant={'h4'} fontWeight={'bold'}>
									Total:{' '}
									{cart.total.toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</CardPayment>
		</Box>
	);
}

const ProductItem = ({product, handleChangeAmount}: {product: IProduct; handleChangeAmount: THandleChangeAmount}) => {
	product.thumbUrl = product.thumbUrl.length ? product.thumbUrl : PRODUCT_IMG_DEFAULT;

	return (
		<Box>
			<Grid container spacing={4}>
				<Grid item xs={4} sm={4}>
					<Box sx={{}}>
						<img
							style={{
								width: '100%',
							}}
							src={product.thumbUrl}
						/>
					</Box>
				</Grid>
				<Grid item xs={8} sm={8}>
					<Box>
						<Box>
							<Typography variant={'h3'} fontWeight={'bold'}>
								{product.nome}
							</Typography>
							<Chip
								sx={{my: 1, p: 1}}
								color={'secondary'}
								label={
									<Typography color={'secondary'} variant={'h4'}>
										{ToggleLetters(product.tipo)}
									</Typography>
								}
								size="small"
								variant="outlined"
							/>
						</Box>
						<Box mt={1} display={'flex'} alignItems={'center'} justifyContent={'start'}>
							<ToggleButtonGroup exclusive aria-label="text alignment">
								<ToggleButton
									disabled={product.quantidade == 1}
									size={'small'}
									onClick={() => handleChangeAmount(product.quantidade, product.idProdutoCarrinho, 'remove')}
									sx={{borderColor: DARK_MAIN}}
									value="left"
									aria-label="left aligned"
								>
									<Remove fontSize={'small'} />
								</ToggleButton>
								<ToggleButton size={'small'} sx={{borderColor: DARK_MAIN}} value="center" aria-label="centered">
									<Typography px={2} variant={'h4'}>
										{product.quantidade}
									</Typography>
								</ToggleButton>
								<ToggleButton
									size={'small'}
									onClick={() => handleChangeAmount(product.quantidade, product.idProdutoCarrinho, 'add')}
									sx={{borderColor: DARK_MAIN}}
									value="center"
									aria-label="centered"
								>
									<Add fontSize={'small'} />
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						{/*<Typography variant={'h4'} mt={2} fontWeight={'bold'} >Valor: {product.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography>*/}
						<Typography variant={'h4'} mt={1} fontWeight={'bold'}>
							{parseFloat(product.valor).toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL',
							})}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Divider sx={{my: 2}} />
		</Box>
	);
};
