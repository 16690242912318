import {Grid, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import BtnDefault from '../../../../components/BtnDefault/BtnDefault';
import ToastContainer from '../../../../components/ToastContainer/ToastContainer';
import {SHORT_TOAST_DURATION} from '../../../../constants/toast';
import api, {IErrorResponse} from '../../../../shared/api';
import {useBackdropState} from '../../../../shared/contexts/backdropContext';
import {useSessionContext} from '../../../../shared/contexts/sessionContext';
import {EnumToast} from '../../../../shared/enums/toast';
import {required} from '../../../../shared/helpers/reactFormHookValidation';
import {ICart} from '../../../../shared/interfaces/iCart';
import {ICouponReq} from '../../../../shared/interfaces/requests/coupon';
import {TToastState} from '../../../../shared/providers/ToastProvider';
import {ACTION_MANAGE} from '../../../../shared/types/actions';

interface IDiscountCouponProps {}

interface ICoupon {
	coupon: string;
	action: ACTION_MANAGE;
}

export default function DiscountCoupon(props: IDiscountCouponProps) {
	const {session, setSession} = useSessionContext();
	const {setBackdropVisibility} = useBackdropState();
	const [toast, setToast] = useState<TToastState>(null);

	let haveCoupon: null | string = null;

	Object.values(session.cart!.produtos).map(product => {
		if (product.idCupom > 0) {
			haveCoupon = product.codigo;
		}
	});
	const handleCheckCoupon = (values: ICoupon) => {
		setBackdropVisibility(true);

		api
			.post<ICouponReq, ICart>('manageCoupon', {
				action: values.action,
				code: values.coupon,
				idCarrinho: session.cart!.idCarrinho,
			})
			.then(({data}) => {
				// console.log(data);

				setSession({
					...session,
					cart: data.data,
				});

				const newAction = values.action === 'add' ? 'remove' : 'add';

				setValue('action', newAction);
				if (newAction === 'add') {
					setValue('coupon', '');
				}

				setToast({
					type: data.success ? 1 : 0,
					text: data.message,
				});
			})
			.catch(({response: {data}}: IErrorResponse) => {
				setToast({
					type: EnumToast.ERROR,
					text: data.message,
				});
			})
			.finally(() => {
				setBackdropVisibility(false);
			});
	};

	const ctrls = useForm<ICoupon>({
		shouldFocusError: true,
		defaultValues: {
			coupon: haveCoupon ? haveCoupon : '',
			action: 'add',
		} as ICoupon,
		// resolver: yupResolver(schema),
	});

	const {
		register,
		getValues,
		handleSubmit,
		control,
		formState: {errors},
		setValue,
		unregister,
	} = ctrls;

	// console.log('haveCoupon',haveCoupon)
	return (
		<>
			<FormProvider {...ctrls}>
				<form onSubmit={ctrls.handleSubmit(handleCheckCoupon)}>
					<Grid item xs={12}>
						<Typography variant={'h5'} fontWeight={'bold'}>
							Adicionar cupom de desconto
						</Typography>
						<Box display={'flex'} width={'100%'} mt={1} sx={{position: 'relative'}}>
							<Controller
								control={control}
								name={`coupon`}
								rules={{
									required: required(),
								}}
								render={({field}) => (
									<TextField
										size={'small'}
										label={'Insira o código'}
										error={Boolean(errors?.coupon)}
										disabled={!!haveCoupon}
										helperText={errors?.coupon?.message}
										{...field}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											const coupon = e.target.value;
											setValue('coupon', coupon.toUpperCase());
										}}
									/>
								)}
							/>
							<input {...register(`action`)} type={'hidden'} />
							<Box>
								<BtnDefault
									sx={{marginLeft: 2}}
									props={{
										type: 'submit',
									}}
									onClick={() => {}}
								>
									{haveCoupon ? 'Remover' : 'Aplicar'}
								</BtnDefault>
							</Box>
						</Box>
					</Grid>
				</form>
			</FormProvider>

			<Box>
				<ToastContainer toast={toast} setToast={setToast} duration={SHORT_TOAST_DURATION} />
			</Box>
		</>
	);
}
