import Box from '@mui/material/Box';
import {Button, useTheme} from '@mui/material';
import {FaChevronLeft} from 'react-icons/fa';
import Typography from '@mui/material/Typography';
import React from 'react';
import {STEPS} from '../../pages/pagamento';

interface IProps {
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	disabled?: boolean;
}

export default function PaymentButtons({activeStep, setActiveStep, disabled}: IProps) {
	const theme = useTheme();

	const handleBack = () => {
		setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
	};
	const buttonProps =
		activeStep === 0
			? {
					onClick: () => setActiveStep(activeStep + 1),
					type: 'button',
				}
			: {
					type: 'submit',
				};

	return (
		<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Button
				hidden={true}
				sx={{visibility: activeStep <= 0 ? 'hidden' : 'visible', my: 4}}
				variant="text"
				startIcon={<FaChevronLeft color={theme.palette.primary.main} />}
				type="button"
				onClick={() => handleBack()}
			>
				<Typography variant={'h4'} color={'text.primary'}>
					Voltar
				</Typography>
			</Button>
			<Button color="primary" disabled={disabled === undefined ? false : disabled} href={''} sx={{my: 4}} variant="contained" {...buttonProps}>
				{activeStep === STEPS.length - 1 ? 'Realizar pagamento' : 'Avançar'}
			</Button>
		</Box>
	);
}
