import Container from '@mui/material/Container';
import React from 'react';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material';

export default function CustomContainer({children, sx}: {children: any; sx?: SxProps<Theme>}) {
	return (
		<Container maxWidth="md" sx={{position: 'relative', ...sx}}>
			{children}
		</Container>
	);
}
