import axios, {AxiosRequestConfig} from 'axios';
import ENV from '../configs/environment';

export interface IRequest {
	system: number;
	urlRequested: string;
	responseCode: number;
	message: string;
	extraData: {
		name: string;
		message: string;
		stack?: string;
		componentStack: string;
	};
}

export interface IResponse {
	success: boolean;
	message: string;
}

class DiscordApi {
	private readonly endpointsUrl: {[key: string]: string};

	private apiRequestUrl = '';
	private requestConfig: AxiosRequestConfig = {
		headers: {
			'Access-Control-Allow-Origin': '*',
		},
	};

	constructor() {
		this.endpointsUrl = {
			alert: `${ENV.baseUrlDiscordBot}/prod/alert`,
		};
	}

	private setUrl = (endpoint: string) => {
		if (this.endpointsUrl[endpoint] === undefined) {
			throw new Error(`Endpoint informado não encontrado: ${endpoint}`);
		}

		this.apiRequestUrl = this.endpointsUrl[endpoint];
	};

	post(endpoint: string, req: IRequest) {
		this.setUrl(endpoint);
		return axios.post<IResponse>(this.apiRequestUrl, req, this.requestConfig);
	}
}

export default new DiscordApi();
