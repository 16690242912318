import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import ENV from 'shared/configs/environment';

interface IProps {
	userDocument: string;
}

export default function SecMaisDeUm({userDocument}: IProps) {
	const formLink = `/formulario?buyer=${userDocument}`;
	const fullFormLink = `${ENV.baseUrl}${formLink}`;
	const navigate = useNavigate();

	return (
		<Box>
			<Box>
				<Typography variant={'h3'} color={'primary'} fontWeight={'bold'}>
					IMPORTANTE!
				</Typography>
				<Typography mb={1} variant={'h4'}>
					Você receberá em seu e-mail um link para preencher os dados dos demais participantes dos cursos adquiridos.
					<br />
					Se preferir, clique no botão abaixo para preencher o formulário agora mesmo.
				</Typography>
			</Box>
			<Button variant={'contained'} onClick={() => navigate(formLink)}>
				Preencher agora
			</Button>
			<Box>
				<Typography mt={4} variant={'h4'}>
					Ou copie o link abaixo e envie para a pessoa para quem você deseja liberar o acesso ao curso adquirido
				</Typography>
				<Typography mt={1} variant={'h4'}>
					{fullFormLink}
				</Typography>
			</Box>
			{/*<Box sx={{mt: 3}} ml={1} display={'inline-block'}>*/}
			{/*    <BtnCopy text={''}  toastDuration={2000}>*/}
			{/*        <Button variant={'outlined'} sx={{*/}
			{/*            borderWidth: '1px',*/}
			{/*            minInlineSize: 'max-content',*/}
			{/*        }}  color={'primary'} startIcon={<RiFileCopyLine/>}>*/}
			{/*            <Typography variant={'h4'} color={'primary'}>Copiar link de preenchimento</Typography>*/}
			{/*        </Button>*/}
			{/*    </BtnCopy>*/}
			{/*</Box>*/}
		</Box>
	);
}
