import {Alert, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {RE_ACTION_LOGIN} from 'constants/recaptchaActions';
import React, {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {Helmet} from 'react-helmet';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import CardPayment from '../../components/CardPayment/CardPayment';
import CustomContainer from '../../components/Container/CustomContainer';
import ToastContainer from '../../components/ToastContainer/ToastContainer';
import PaymentBg from '../../components/paymentBg';
import api, {IErrorResponse} from '../../shared/api';
import {useBackdropState} from '../../shared/contexts/backdropContext';
import {useSessionContext} from '../../shared/contexts/sessionContext';
import {LS} from '../../shared/enums/localstorage';
import {EnumToast} from '../../shared/enums/toast';
import {clearValue, maskCpf} from '../../shared/helpers/mask';
import {ICoursePayment} from '../../shared/interfaces/ICoursePayment';
import {ICoursesSubscribeReq, ICoursesSubscribeResp} from '../../shared/interfaces/requests/courses';
import {ILoginReq, ILoginResp} from '../../shared/interfaces/requests/login';
import {TToastState} from '../../shared/providers/ToastProvider';
import SectionBuyer from './sections/SectionBuyer';
import SectionPerson from './sections/SectionPerson';

export interface IControlsForm1 {
	cpf: string;
}

export interface IControlsForm2 extends IControlsForm1 {
	product: string;
}

export default function Formulario() {
	const navigate = useNavigate();
	const [toast, setToast] = useState<TToastState>(null);
	const {session, setSession} = useSessionContext();
	const {setBackdropVisibility} = useBackdropState();

	const [searchParams] = useSearchParams();

	const [products, setProducts] = useState<ICoursePayment[]>([]);
	const [personToasts, setPersonToasts] = useState<any>(null);

	const [tokenRecaptcha, setTokenRecaptcha] = useState<string | undefined>();
	const {executeRecaptcha} = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}
		const token = await executeRecaptcha(RE_ACTION_LOGIN);
		setTokenRecaptcha(token);
	}, [executeRecaptcha]);

	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	const buyerCpf = maskCpf(searchParams.get('buyer') || '');
	const personCpf = maskCpf(session.session?.user.cpf || '');

	const ctrlsForm1 = useForm<IControlsForm1>({
		shouldFocusError: true,
		defaultValues: {
			cpf: buyerCpf,
		} as IControlsForm1,
	});

	const handleSubmit = ({product, cpf}: IControlsForm2) => {
		const cartToken = localStorage.getItem(LS.CARTTOKEN);

		if (cartToken === null) {
			ctrlsForm1.reset();
			return false;
		}
		if (!tokenRecaptcha) {
			setToast({
				type: EnumToast.ERROR,
				text: 'Por favor, aguarde a confirmação de que você não é um robô.',
			});
			return;
		}

		setBackdropVisibility(true);

		api
			.post<ILoginReq, ILoginResp>('login', {
				cpf: cpf,
				token: cartToken,
				recaptcha: tokenRecaptcha,
			})
			.then(({data}) => {
				if (data.data.userFinded) {
					localStorage.setItem(
						LS.USER_SESSION,
						JSON.stringify({
							cart: data.data.carrinho!,
							session: {
								user: {
									nome: data.data.user!.name!,
									email: data.data.user!.email!,
									cpf: clearValue(cpf),
								},
								token: data.data.token!,
							},
						})
					);

					const formBuyerCpf = clearValue(ctrlsForm1.getValues('cpf'));

					api
						.post<ICoursesSubscribeReq, ICoursesSubscribeResp>('formSubscribe', {
							course: product,
							cpf: clearValue(cpf),
							buyerCpf: formBuyerCpf,
						})
						.then(({data}) => {
							if (data.data.success) {
								navigate('/formulario/concluir', {
									state: {
										buyer: clearValue(formBuyerCpf),
									},
								});
							} else {
								setToast({
									type: EnumToast.ERROR,
									text: 'Ocorreu um erro desconhecido ao processar sua solicitação',
								});
							}
						})
						.catch(({response: {data}}: IErrorResponse) => {
							setToast({
								type: EnumToast.ERROR,
								text: data.message,
							});
						})
						.finally(() => {
							setBackdropVisibility(false);
						});
				} else {
					setBackdropVisibility(false);
				}
			})
			.catch(({response: {data}}: IErrorResponse) => {
				if (data.code === 1090) {
					const url = `/formulario?buyer=${clearValue(ctrlsForm1.getValues('cpf'))}&person=${clearValue(cpf)}`;

					const action = () => {
						setSession({
							...session,
							session: undefined,
						});
						navigate('/cadastro', {
							state: {
								cpf: clearValue(cpf),
								redirect: encodeURIComponent(url),
							},
						});
					};

					setPersonToasts(
						<Alert severity="error">
							<Typography>Notamos que você ainda não possui uma conta. Crie sua conta para prosseguir</Typography>
							<Button sx={{mt: 1}} variant={'outlined'} type={'button'} onClick={action}>
								Criar Conta
							</Button>
						</Alert>
					);
				} else {
					setToast({
						type: EnumToast.ERROR,
						text: data.message,
					});
				}
				setBackdropVisibility(false);
			})
			.finally(() => {});
	};

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Liberção de acesso</title>
				{/*Cadastre as pessoas que terão acesso ao produto adquirido*/}
			</Helmet>
			<CustomContainer sx={{pt: 6, pb: 6 * 2}}>
				<Typography fontWeight={'bold'} variant={'h1'} mb={2}>
					Liberação de acesso
				</Typography>
				<Typography variant={'h4'}>Preencha o formulário abaixo para liberar um curso adquirido para outra pessoa.</Typography>
				<ToastContainer toast={toast} setToast={setToast} />
				<CardPayment>
					<FormProvider {...ctrlsForm1}>
						<SectionBuyer buyerCpf={buyerCpf} setProducts={setProducts} />
					</FormProvider>
					{products?.length > 0 ? (
						<SectionPerson personToasts={personToasts} personCpf={personCpf} products={products} onSubmit={handleSubmit} />
					) : null}
				</CardPayment>
			</CustomContainer>
		</PaymentBg>
	);
}
