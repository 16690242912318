import axios from 'axios';

export interface ICepErrorResponse {
	erro: string;
}

export interface ICepResponse {
	bairro: string;
	cep: string;
	complemento: string;
	ddd: string;
	gia: string;
	ibge: string;
	localidade: string;
	logradouro: string;
	siafi: string;
	uf: string;
}

export function getCepLocation(cep: any) {
	const baseUrl = 'https://viacep.com.br/ws/';
	return axios.get(`${baseUrl}${cep}/json`, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		},
	});
}
