import {Button} from '@mui/material';
import React from 'react';

interface IProps {
	onClick: any;
	sx?: any;
	children?: any;
	props?: any;
}

export default function BtnDefault(props: IProps) {
	return (
		<Button
			variant={'outlined'}
			sx={{
				...props.sx,
				borderWidth: '1px',
				minInlineSize: 'max-content',
			}}
			color={'primary'}
			onClick={props.onClick}
			{...props.props}
		>
			{props.children}
		</Button>
	);
}
