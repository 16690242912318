import {FormControl, FormHelperText, Grid, MenuItem, Select, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useState} from 'react';
import Card, {Focused} from 'react-credit-cards';
import {Controller, FieldValues, useFormContext, useWatch} from 'react-hook-form';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {usePaymentDataState} from '../../../../shared/contexts/paymentDataContext';
import {formatInputNumber, maskCreditCard, maskYearAndMonth} from '../../../../shared/helpers/mask';
import {lenght, maxLenght, minLenght, required} from '../../../../shared/helpers/reactFormHookValidation';
import {ICard} from '../../../../shared/interfaces/ICard';

const CARD_INDEX: {[name: string]: string} = {
	nome: 'name',
	numero: 'number',
	codigoSeguranca: 'cvc',
	validadeCartao: 'expiry',
};

interface ICardProps {
	index: string;
}
export default function CardInfo({index}: ICardProps) {
	const [cardFocus, setCardFocus] = useState<Focused>('number');

	const ctrls = useFormContext<{[index: string]: ICard}>();
	const {
		getValues,
		control,
		formState: {errors},
		setValue,
	} = ctrls;
	const {paymentData} = usePaymentDataState();

	const handleFocus = (e: any) => {
		// console.log('Focus',e)
		const elementName = e.target?.name.split('.', 2)[1];
		setCardFocus(CARD_INDEX[elementName] as Focused);
	};

	return (
		<Box>
			<Box mb={4}>
				<MyCard focus={cardFocus} formState={ctrls} index={index} />
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Controller
						control={control}
						name={`${index}.numero`}
						rules={{
							minLength: minLenght(18, 15),
							maxLength: maxLenght(19, 16),
							required: required(),
						}}
						render={({field}) => (
							<TextField
								fullWidth
								size={'small'}
								label={'Número do cartão'}
								error={Boolean(errors[index]?.numero)}
								helperText={errors[index]?.numero?.message}
								{...field}
								onFocus={e => handleFocus(e)}
								onChange={e => {
									setValue(`${index}.numero`, maskCreditCard(e.target.value));
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controller
						control={control}
						name={`${index}.validadeCartao`}
						rules={{
							...lenght(5, 4),
							required: required(),
						}}
						render={({field}) => (
							<TextField
								fullWidth
								size={'small'}
								inputProps={{maxLength: 5}}
								label={'Validade mês/ano'}
								error={Boolean(errors[index]?.validadeCartao)}
								helperText={errors[index]?.validadeCartao?.message}
								{...field}
								onFocus={handleFocus}
								onChange={e => {
									setValue(`${index}.validadeCartao`, maskYearAndMonth(e.target.value));
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controller
						control={control}
						name={`${index}.codigoSeguranca`}
						rules={{
							required: required(),
							minLength: minLenght(3),
						}}
						render={({field}) => (
							<TextField
								fullWidth
								size={'small'}
								inputProps={{maxLength: 4}}
								label={'Código de segurança'}
								error={Boolean(errors[index]?.codigoSeguranca)}
								helperText={errors[index]?.codigoSeguranca?.message}
								{...field}
								onFocus={handleFocus}
								onChange={e => {
									setValue(`${index}.codigoSeguranca`, formatInputNumber(e.target.value));
								}}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						name={`${index}.numeroParcelas`}
						control={control}
						defaultValue={getValues(`${index}.numeroParcelas`)}
						rules={{
							required: required(),
						}}
						render={({field}) => (
							<FormControl fullWidth error={Boolean(errors[index]?.numeroParcelas)}>
								<Select fullWidth size={'small'} label="" onFocus={() => setCardFocus('number')} {...field}>
									{Object.values(paymentData.parcels).map(parcel => (
										<MenuItem key={parcel.idParcela} value={parcel.idParcela}>{`Em ${parcel.quantidade}X sem juros`}</MenuItem>
									))}
								</Select>
								<FormHelperText>{errors[index]?.numeroParcelas?.message}</FormHelperText>
							</FormControl>
						)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

interface IProps {
	formState: UseFormReturn<FieldValues, any>;
	focus: Focused;
	index: string;
	children?: any;
}

const MyCard = ({formState, index, focus}: IProps) => {
	useWatch({
		control: formState.control,
		name: `${index}`,
	});

	// console.log(`Renderizou o ${index}`)

	return (
		<Card
			{...{
				locale: {
					valid: 'Validade',
				},
				cvc: formState.getValues(`${index}.codigoSeguranca`),
				expiry: formState.getValues(`${index}.validadeCartao`),
				focused: focus,
				name: ' ',
				number: formState.getValues(`${index}.numero`),
			}}
		/>
	);
};
