import {Button, Grid, TextField, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Theme} from '@mui/material/styles/createTheme';
import {ERR_USER_WITHOUT_PRODUCTS} from 'constants/errors';
import {RE_ACTION_LOGIN} from 'constants/recaptchaActions';
import React, {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {Helmet} from 'react-helmet';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import CardPayment from '../../components/CardPayment/CardPayment';
import CustomContainer from '../../components/Container/CustomContainer';
import ToastContainer from '../../components/ToastContainer/ToastContainer';
import PaymentBg from '../../components/paymentBg';
import api, {IErrorResponse} from '../../shared/api';
import {useBackdropState} from '../../shared/contexts/backdropContext';
import {useSessionContext} from '../../shared/contexts/sessionContext';
import {LS} from '../../shared/enums/localstorage';
import {EnumToast} from '../../shared/enums/toast';
import {clearValue, maskCpf} from '../../shared/helpers/mask';
import {isCpf, required} from '../../shared/helpers/reactFormHookValidation';
import {ILoginReq, ILoginResp} from '../../shared/interfaces/requests/login';
import {TToastState} from '../../shared/providers/ToastProvider';

interface LoginFormData {
	cpf: string;
}

export default function Login() {
	const navigate = useNavigate();

	const [buttomIsActiveState, setButtomIsActiveState] = useState(false);
	const {setBackdropVisibility} = useBackdropState();
	const [searchParams] = useSearchParams();
	const {session, setSession} = useSessionContext();
	const [toast, setToast] = useState<TToastState>(null);
	const [tokenCart, setTokenCart] = useState(searchParams.get('tokenCart'));
	const [tokenRecaptcha, setTokenRecaptcha] = useState<string | undefined>();
	let redirect = searchParams.get('redirect');

	const {executeRecaptcha} = useGoogleReCaptcha();

	useEffect(() => {
		if (redirect) {
			localStorage.setItem(LS.REDIRECT, redirect);
		}

		if (tokenCart) {
			if (localStorage.getItem(LS.CARTTOKEN) && session.session !== undefined) {
				setSession({
					cart: undefined,
					session: undefined,
				});
			}

			localStorage.setItem(LS.CARTTOKEN, tokenCart);
		} else {
			const token = localStorage.getItem(LS.CARTTOKEN);

			if (token && token.length) {
				setTokenCart(token);
			} else {
				const redirect = localStorage.getItem(LS.REDIRECT);

				if (redirect) {
					window.location.href = redirect;
				} else {
					window.location.href = process.env.REACT_APP_BASEURL_CURSOS as string;
				}
			}
		}

		if (session.session !== undefined) {
			if (session.cart !== undefined) {
				if (session && (!session.cart || !session.cart.produtos)) {
				} else {
					navigate('/pagamento');
				}
			} else {
				setSession({
					cart: undefined,
					session: undefined,
				});
			}
		}
	}, [session, tokenCart]);

	const ctrls = useForm<LoginFormData>({
		shouldFocusError: true,
		defaultValues: {
			cpf: '',
		},
	});

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}

		const token = await executeRecaptcha(RE_ACTION_LOGIN);
		setTokenRecaptcha(token);
	}, [executeRecaptcha]);

	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	const onSubmit = async (values: any) => {
		if (!tokenRecaptcha) {
			setToast({
				type: EnumToast.ERROR,
				text: 'Por favor, aguarde a confirmação de que você não é um robô.',
			});
			return;
		}

		const cpf = clearValue(values.cpf);
		setBackdropVisibility(true);

		api
			.post<ILoginReq, ILoginResp>('login', {
				cpf: cpf,
				token: tokenCart!,
				recaptcha: tokenRecaptcha,
			})
			.then(({data}) => {
				// console.log(data)

				if (data.data.userFinded) {
					setSession({
						cart: data.data.carrinho!,
						session: {
							user: {
								nome: data.data.user!.name!,
								email: data.data.user!.email!,
								cpf: cpf,
							},
							token: data.data.token!,
						},
					});

					if (!data.data.carrinho || !data.data.carrinho.produtos) {
						setToast({
							type: EnumToast.ERROR,
							text: ERR_USER_WITHOUT_PRODUCTS,
						});
					} else {
						navigate('/pagamento');
					}
				} else {
					navigate('/cadastro', {
						state: {
							cpf: cpf,
						},
					});
				}
			})
			.catch(({response: {data}}: IErrorResponse) => {
				// console.log(data)

				if (data.code === 1090) {
					navigate('/cadastro', {
						state: {
							cpf: cpf,
						},
					});
				} else {
					setToast({
						type: EnumToast.ERROR,
						text: data.message,
					});
				}
			})
			.finally(() => {
				setBackdropVisibility(false);
			});
	};

	const {
		setValue,
		control,
		formState: {errors},
	} = ctrls;

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Realize seu pagamento com segurança</title>
			</Helmet>
			<CustomContainer sx={{pt: 6}}>
				<ToastContainer toast={toast} setToast={setToast} />
				<CardPayment>
					<FormProvider {...ctrls}>
						<form onSubmit={ctrls.handleSubmit(onSubmit)}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={8}>
									<Typography fontWeight={'bold'} variant={'h3'} mb={4}>
										Olá! Você está na área de compra de produtos TXAI. <br />
										Preencha o campo abaixo para continuar:
									</Typography>
									<Typography fontWeight={'bold'} variant={'h5'} mb={2}>
										Insira seu CPF abaixo para continuar:
									</Typography>
									<Box sx={{display: 'flex'}}>
										<Controller
											control={control}
											name="cpf"
											rules={{
												...isCpf,
												required: required(),
											}}
											render={({field}) => (
												<TextField
													fullWidth
													size={'small'}
													id="cpf"
													{...field}
													label="CPF - Somente números"
													sx={{backgroundColor: 'white'}}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
														const cpf = clearValue(e.target.value);
														if (cpf.length >= 11) {
															!buttomIsActiveState && setButtomIsActiveState(true);
														} else {
															buttomIsActiveState && setButtomIsActiveState(false);
														}

														setValue('cpf', maskCpf(e.target.value));
													}}
													error={Boolean(errors.cpf)}
													helperText={errors.cpf && `O CPF digitado é inválido `}
												/>
											)}
										/>
										<Box>
											<Button
												disabled={!buttomIsActiveState}
												sx={{
													ml: '-5px',
												}}
												variant="contained"
												size={'medium'}
												type="submit"
											>
												Avançar
											</Button>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} md={4}>
									<Box sx={{position: 'relative'}} hidden={useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))}>
										<img
											style={{
												width: '200px',
												display: 'block',
												position: 'absolute',
												top: '8px',
												left: '60px',
											}}
											src={'/images/paymentOptions/image3.svg'}
										/>
									</Box>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</CardPayment>
			</CustomContainer>
		</PaymentBg>
	);
}
