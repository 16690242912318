import Box from '@mui/material/Box';
import React from 'react';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

export default function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<Box role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && <Box sx={{}}>{children}</Box>}
		</Box>
	);
}
