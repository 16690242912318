import {Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CONTRACT_TEXT} from 'constants/content/contractText';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import CardPayment from '../../../../components/CardPayment/CardPayment';
import {WHITE_FILL} from '../../../../constants/ui/colors';
import {required} from '../../../../shared/helpers/reactFormHookValidation';
import theme from '../../../../theme';
import FiscalNote from './fiscalNote';

interface IProps {}

export default function Step2({}: IProps) {
	const ctrls = useFormContext();
	const {
		getValues,
		control,
		formState: {errors},
		setValue,
	} = ctrls;

	return (
		<Box>
			<CardPayment>
				<Grid container pt={2} spacing={4}>
					<Grid item xs={12} sm={6} pt={2}>
						<FiscalNote />
					</Grid>
					<Grid item xs={12} sm={6} pt={2}>
						<Card sx={{p: 0}} variant={'outlined'}>
							<CardContent sx={{padding: '0px'}}>
								<Box sx={{p: 3}}>
									<Typography variant={'h4'} fontWeight={'bold'}>
										Contrato de boa convivência
									</Typography>
								</Box>
								<Divider />
								<TextField
									fullWidth
									variant={'filled'}
									label={''}
									value={CONTRACT_TEXT}
									sx={{
										fontSize: `${theme.typography.h5.fontSize}px`,
										backgroundColor: WHITE_FILL,
									}}
									rows={10}
									multiline
									disabled
								/>
								<Box sx={{p: 3, pb: 0}}>
									<FormGroup>
										<Controller
											control={control}
											name={`termos`}
											rules={{
												required: required(),
											}}
											render={({field}) => (
												<FormControlLabel
													control={
														<Checkbox
															checked={getValues('termos')}
															sx={{p: 0}}
															name={field.name}
															onChange={e => {
																// console.log(e.target.checked)
																setValue('termos', e.target.checked);
															}}
															inputProps={{'aria-label': 'controlled'}}
														/>
													}
													label={<Typography variant={'h4'}>Li e concordo com os termos acima.</Typography>}
												/>
											)}
										/>
										<Typography color={'error'} variant={'h5'}>
											{`${errors.termos?.message ?? ''}`}
										</Typography>
									</FormGroup>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</CardPayment>
		</Box>
	);
}
