import React from 'react';
import {Helmet} from 'react-helmet';

export default function Header() {
	return (
		<Helmet>
			<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
			<meta property="og:type" content="Grupo Txai" />
			<link rel="icon" href="https://home.txaiacademia.com.br/uploads/imagens/logos/favico.png" />
			<meta name="image" content="https://cdn.txaiacademia.com.br/images/logos/txai_min.png" />

			<title>Pagamentos Txai</title>
		</Helmet>
	);
}
