import {Controller, FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {Button, Grid, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {isCpf, required} from '../../../../shared/helpers/reactFormHookValidation';
import React, {FC} from 'react';
import {maskCpf} from '../../../../shared/helpers/mask';
import CoursesDisponibles from './CoursesDisponibles';
import {ICoursePayment} from '../../../../shared/interfaces/ICoursePayment';

interface IProps {
	personCpf: string;
	onSubmit: SubmitHandler<IControlsForm2>;
	products: ICoursePayment[];
	personToasts: any;
}
export interface IControlsForm2 {
	product: string;
	cpf: string;
}
const SectionPerson: FC<IProps> = ({personCpf, onSubmit, products, personToasts}) => {
	// const  ctrlsForm2 = useFormContext()
	// const { register, getValues, handleSubmit,control, formState:{ errors }, setValue, unregister } = ctrlsForm2;

	const ctrlsForm2 = useForm<IControlsForm2>({
		shouldFocusError: true,
		defaultValues: {
			cpf: personCpf,
			product: '0',
		} as IControlsForm2,
	});

	return (
		<FormProvider {...ctrlsForm2}>
			<form onSubmit={ctrlsForm2.handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant={'h4'} mb={2}>
								CPF do aluno que participará do curso
							</Typography>
							<Controller
								control={ctrlsForm2.control}
								name="cpf"
								rules={{
									...isCpf,
									required: required(),
								}}
								render={({field}) => (
									<TextField
										fullWidth
										size={'small'}
										id="cpf"
										{...field}
										label="CPF - Somente números"
										sx={{backgroundColor: 'white'}}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											ctrlsForm2.setValue('cpf', maskCpf(e.target.value));
										}}
										error={Boolean(ctrlsForm2.formState.errors.cpf)}
										helperText={ctrlsForm2.formState.errors.cpf && `O CPF digitado é inválido `}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						{<CoursesDisponibles products={products} />}
					</Grid>
					<Grid item xs={12}>
						{personToasts}
					</Grid>
					<Grid item xs={12}>
						<Box display={'flex'} justifyContent={'end'}>
							{/*<Button  variant="contained" disabled={!(ctrlsForm2.getValues('cpf').length >= 14)} size={'medium'} type="submit">*/}
							<Button variant="contained" size={'medium'} type="submit">
								Liberar Acesso
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};

export default SectionPerson;
