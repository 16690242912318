import React, {FC} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

interface IProps {
	isLogged: boolean;
	redirectPath: string;
}

const ProtectedRoute: FC<IProps> = ({isLogged, redirectPath}: IProps) => {
	if (!isLogged) {
		return <Navigate to={redirectPath} replace />;
	}

	return <Outlet />;
};

export default ProtectedRoute;
