import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import {ArrowDropDown, Logout} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {WHITE_MAIN} from '../../../constants/ui/colors';
import {useSessionContext} from '../../../shared/contexts/sessionContext';
import {AppBar, Avatar, Button, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip} from '@mui/material';
import api from '../../../shared/api';
import {useNavigate} from 'react-router-dom';
import {useBackdropState} from '../../../shared/contexts/backdropContext';

interface IMenuItem {
	icon: any;
	name: string;
	action: () => void;
}

const Topbar = () => {
	const {session, setSession} = useSessionContext();

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const {setBackdropVisibility} = useBackdropState();

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		handleCloseUserMenu();
		setBackdropVisibility(true);

		api.post<{}, {}>('logout', {}).finally(() => {
			setBackdropVisibility(false);
			setSession({
				...session,
				session: undefined,
			});
			navigate('/login');
		});
	};

	const userOptions: IMenuItem[] = useMemo(
		() => [
			{
				name: 'Deslogar',
				icon: <Logout fontSize="small" />,
				action: handleLogout,
			},
		],
		[]
	);

	const LoginComponent =
		session && session.session !== undefined && session.session.user !== undefined ? (
			<Box>
				<Tooltip title="Opções">
					<Button onClick={handleOpenUserMenu} sx={{p: 0}}>
						<Avatar alt={session.session!.user.nome} />
						<Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-end',
								}}
							>
								<Typography mx={1} textAlign={'center'} variant={'h4'}>
									{' '}
									{session.session!.user.nome}{' '}
								</Typography>
								<ArrowDropDown sx={{color: 'black'}} />
							</Box>
						</Box>
					</Button>
				</Tooltip>
				<Menu
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					id="menu-appbar"
					anchorEl={anchorElUser}
					transformOrigin={{horizontal: 'right', vertical: 'top'}}
					anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
					keepMounted
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					{userOptions.map((userOption, key) => (
						<MenuItem key={key} onClick={userOption.action}>
							<ListItemIcon>{userOption.icon}</ListItemIcon>
							<Typography textAlign="center">{userOption.name}</Typography>
						</MenuItem>
					))}
				</Menu>
			</Box>
		) : null;

	return (
		<AppBar
			position="static"
			color={'transparent'}
			sx={{
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
				background: `linear-gradient( ${WHITE_MAIN}, ${WHITE_MAIN})`,
				position: 'relative',
			}}
		>
			<Toolbar disableGutters>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						px: 2,
					}}
				>
					<img
						style={{
							width: '87px',
						}}
						src="https://cdn.txaiacademia.com.br/images/logos/grupotxai_min.png"
						alt={'Logo da Txai'}
					/>
					{LoginComponent}
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Topbar;
