import {AlertColor} from '@mui/material';
import {EnumToast} from '../shared/enums/toast';

export const DEFAULT_TOAST_DURATION = 8000;
export const SHORT_TOAST_DURATION = 3000;

const DEFAULT_SUCCESS_TEXT = 'Operação realizada com sucesso!';
const DEFAULT_ERROR_TEXT = 'Ocorreu um erro ao realizar sua operação';

interface IToastOptions {
	text: string;
	severity: AlertColor;
}

export const ToastOptions: {[index: number]: IToastOptions} = {
	[EnumToast.ERROR]: {
		text: DEFAULT_ERROR_TEXT,
		severity: 'error',
	},
	[EnumToast.SUCCESS]: {
		text: DEFAULT_SUCCESS_TEXT,
		severity: 'success',
	},
	[EnumToast.INFO]: {
		text: '',
		severity: 'info',
	},
};
