import {Alert, Snackbar} from '@mui/material';
import React, {Dispatch} from 'react';
import {TToastState} from '../../shared/providers/ToastProvider';
import {DEFAULT_TOAST_DURATION, ToastOptions} from '../../constants/toast';
import Typography from '@mui/material/Typography';

//https://mui.com/pt/material-ui/react-snackbar/

interface IProps {
	toast: TToastState;
	setToast?: Dispatch<TToastState>;
	duration?: number;
}

export default function ToastContainer({toast, setToast, duration = DEFAULT_TOAST_DURATION}: IProps) {
	if (toast === null) {
		return null;
	}

	const handleClose = () => {
		if (setToast !== undefined) {
			setToast(null);
		}
	};

	return (
		<Snackbar
			sx={{position: 'relative', bottom: '0px !important', left: '0px !important'}}
			open={!!toast}
			autoHideDuration={duration}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={ToastOptions[toast.type].severity} sx={{width: '100%', mt: 1}}>
				<Typography variant={'h4'}>{toast.text}</Typography>
			</Alert>
		</Snackbar>
	);
}
// <Alert severity="warning">This is a warning message!</Alert>

// <Snackbar sx={{position: 'absolute', zIndex: '3', height: '55px'}} open={!!toast} autoHideDuration={duration} onClose={handleClose} >
//     <Alert onClose={handleClose} severity={ToastOptions[toast.type].severity} sx={{ width: '100%' }}>
//         <Typography variant={'h4'}>{toast.text}</Typography>
//     </Alert>
// </Snackbar>
