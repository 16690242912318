import axios, {AxiosRequestConfig} from 'axios';
import ENV from '../configs/environment';
import {LS} from '../enums/localstorage';
import {ISession} from '../interfaces/iSession';

export interface IResponse<T> {
	code: number;
	success: boolean;
	message: string;
	data: T;
}

export interface IErrorResponse {
	response: {
		data: IResponse<null>;
	};
}

class Api {
	private readonly endpointsUrl: {[key: string]: string};

	private apiRequestUrl = '';
	private requestConfig: AxiosRequestConfig = {
		timeout: 20000,
		timeoutErrorMessage: 'Tempo de requisição excedido',
	};

	private endpointsRequiredToken: Array<string>;

	constructor() {
		this.endpointsUrl = {
			login: `${ENV.baseUrlPagamentos}/purchase/login/verify`,
			subscribe: `${ENV.baseUrlPagamentos}/purchase/login/subscribe`,
			logout: `${ENV.baseUrlPagamentos}/purchase/logout`,
			manageCoupon: `${ENV.baseUrlPagamentos}/checkout/manageCoupon`,
			changeQuantity: `${ENV.baseUrlPagamentos}/purchase/payment/changequantity`,
			getParcels: `${ENV.baseUrlPagamentos}/purchase/payment/getOptions`,
			executePayment: `${ENV.baseUrlPagamentos}/checkout/realizarPagamento`,
			peopleProducts: `${ENV.baseUrlPagamentos}/`,

			loginAndCheck: `${ENV.baseUrlPagamentos}/api/courses/loginAndCheck`,
			formSubscribe: `${ENV.baseUrlPagamentos}/api/courses/formSubscribe`,
			getCart: `${ENV.baseUrlPagamentos}/api/node/carrinho/get`,
		};

		this.endpointsRequiredToken = ['payment', 'changeQuantity', 'manageCoupon', 'executePayment', 'formSubscribe', 'logout', 'getCart'];
	}

	private configuraUrl = (endpoint: string) => {
		if (this.endpointsUrl[endpoint] === undefined) {
			throw new Error(`Endpoint informado não encontrado: ${endpoint}`);
		}

		if (this.endpointsRequiredToken.find(i => i === endpoint)) {
			const session = localStorage.getItem(LS.USER_SESSION);
			if (session) {
				const token = (JSON.parse(session) as ISession).session!.token;
				this.requestConfig = {
					...this.requestConfig,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			}
		}

		this.apiRequestUrl = this.endpointsUrl[endpoint];
	};

	post<Req, Resp>(endpoint: string, req: Req) {
		this.configuraUrl(endpoint);

		return axios.post<IResponse<Resp>>(this.apiRequestUrl, req, this.requestConfig);
	}
}

export default new Api();
