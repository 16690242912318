import {validateCNPJ, validateCPF} from './validation';
import {UseControllerProps} from 'react-hook-form/dist/types/controller';

declare type RfkValidationOptions = UseControllerProps['rules'];

export const lenght = (value: number, valueText?: number): RfkValidationOptions => ({
	minLength: minLenght(value, valueText),
	maxLength: maxLenght(value, valueText),
});

export const maxLenght = (value: number, valueText?: number | string) => {
	return {
		message: `Deve ter no máximo ${valueText ?? value} caracteres`,
		value: value,
	};
};

export const minLenght = (value: number, valueText?: number) => {
	return {
		message: `Deve ter no mínimo ${valueText ?? value} caracteres`,
		value: value,
	};
};

export const required = () => ({
	message: 'Obrigatório',
	value: true,
});

export const isEmail: RfkValidationOptions = {
	pattern: {
		value: /\S+@\S+\.\S+/,
		message: 'Deve ser um e-mail válido',
	},
};

export const isCep: RfkValidationOptions = {
	...lenght(9, 8),
	pattern: {
		value: /^[0-9]{5}-[0-9]{3}$/,
		message: 'Deve ser um CEP válido',
	},
};

export const isCpf: RfkValidationOptions = {
	validate: value => !validateCPF(value),
	maxLength: maxLenght(14, 11),
	pattern: {
		value: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
		message: 'Deve ser um cpf válido',
	},
};

export const isCnpj: RfkValidationOptions = {
	validate: value => !validateCNPJ(value),
	maxLength: maxLenght(18, 14),
	pattern: {
		value: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
		message: 'Deve ser um CNPJ válido',
	},
};

export const isPhone: RfkValidationOptions = {
	...lenght(15, 11),
	pattern: {
		value: /^\([1-9]{2}\) [0-9]{5}\-[0-9]{4}$/,
		message: 'Deve ser um telefone válido',
	},
};
