import React, {useState} from 'react';
import Router from '../../router';
import Topbar from './Topbar';
import Footer from './Footer/Footer';
import Header from './Header/Header';

// export interface ILayout {}

const Layout: React.FC = () => {
	const [isClosed, setIsClosed] = useState<boolean>(false);
	const handleToggleSidebar = () => {
		setIsClosed(!isClosed);
	};

	return (
		<>
			<Header />
			<Topbar />
			<section style={{padding: '0 15'}}>
				<Router />
			</section>
			<Footer />
		</>
	);
};

export default Layout;
