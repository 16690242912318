export function validateCPF(cpf: string) {
	let i;
	let soma = 0,
		resto;

	const value = cpf.replace(/\./g, '').replace(/-/g, '');

	if (value === '00000000000') return true;

	for (i = 1; i <= 9; i++) {
		soma += parseInt(value.substring(i - 1, i)) * (11 - i);
	}

	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) {
		resto = 0;
	}

	if (resto != parseInt(value.substring(9, 10))) {
		return true;
	}

	soma = 0;

	for (i = 1; i <= 10; i++) {
		soma += parseInt(value.substring(i - 1, i)) * (12 - i);
	}

	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) {
		resto = 0;
	}

	if (resto !== parseInt(value.substring(10, 11))) {
		return true;
	}

	return false;
}

export function validateCNPJ(value: string) {
	// Aceita receber o valor como string, número ou array com todos os dígitos
	const validTypes = Number.isInteger(value) || Array.isArray(value);

	// Elimina valor em formato inválido
	if (!validTypes) return false;

	// Filtro inicial para entradas do tipo string
	// Limita ao máximo de 18 caracteres, para CNPJ formatado
	if (value.length > 18) return false;

	// Teste Regex para veificar se é uma string apenas dígitos válida
	const digitsOnly = /^\d{14}$/.test(value);
	// Teste Regex para verificar se é uma string formatada válida
	const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

	// Se o formato é válido, usa um truque para seguir o fluxo da validação
	if (digitsOnly || validFormat) true;
	// Se não, retorna inválido
	else return false;

	// Guarda um array com todos os dígitos do valor
	const match = value.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) return false;

	// Elimina inválidos com todos os dígitos iguais
	// @ts-ignore
	const items = [...new Set(numbers)];
	if (items.length === 1) return false;

	// Cálculo validador
	const calc = (x: number) => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i];
			sum += n * factor--;
			if (factor < 2) factor = 9;
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12);

	// Valida 1o. dígito verificador
	const digit0 = calc(12);
	if (digit0 !== digits[0]) return false;

	// Valida 2o. dígito verificador
	const digit1 = calc(13);
	return digit1 === digits[1];
}
