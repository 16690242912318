import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IHeaderTextProps {
	userName: string;
}

export default function HeaderText({userName}: IHeaderTextProps) {
	return (
		<Box mb={6}>
			<Typography variant={'h1'} fontWeight={'bold'}>
				Bem-vindo, {userName}!
			</Typography>
			<Typography mt={2} variant={'h3'}>
				Siga as três etapas para concluir sua compra
			</Typography>
		</Box>
	);
}
