import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import CustomContainer from '../../components/Container/CustomContainer';
import PaymentBg from '../../components/paymentBg';
import CardPayment from '../../components/CardPayment/CardPayment';
import {Button} from '@mui/material';
import {RiExternalLinkFill} from 'react-icons/ri';
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {BASE_PLATAFORMA} from '../../constants/appconfig';

export default function Sucesso() {
	const location = useLocation();
	const locationState = location.state as {buyer: string} | null;

	const redirect = `/formulario?buyer=${locationState?.buyer}`;
	const navigate = useNavigate();

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Acesso liberado com sucesso</title>
			</Helmet>
			<CustomContainer sx={{py: 4}}>
				<CardPayment>
					<Box mb={3}>
						<Typography variant={'h1'} color={'primary'} fontWeight={'bold'}>
							PARABÉNS!
						</Typography>
						<Typography mb={3} variant={'h3'}>
							Seu acesso ao curso selecionado foi liberado com sucesso.
						</Typography>
						<Typography variant={'h4'} color={'text.secondary'}>
							O curso está disponível para ser acessado através de nossa plataforma.
						</Typography>
						<Typography variant={'h4'} color={'text.secondary'}>
							Verifique seu e-mail com todas as informações.
						</Typography>
						<Typography variant={'h4'} color={'text.secondary'}>
							Caso precise de ajuda basta clicar no botão do Whatsapp para falar conosco.
						</Typography>
					</Box>
					<Box display={'flex'} justifyContent={'space-between'}>
						<Button variant={'contained'} onClick={() => navigate(redirect)}>
							Liberar outro acesso
						</Button>
						<Button variant={'contained'} endIcon={<RiExternalLinkFill />} href={BASE_PLATAFORMA}>
							Acessar a plataforma
						</Button>
					</Box>
				</CardPayment>
			</CustomContainer>
		</PaymentBg>
	);
}
