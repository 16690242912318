import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Button} from '@mui/material';
import {RiExternalLinkFill} from 'react-icons/ri';
import PaymentBg from '../../../components/paymentBg';
import CustomContainer from '../../../components/Container/CustomContainer';
import ENV from '../../../shared/configs/environment';

export default function CadastroConcluido() {
	const location = useLocation();
	// const locationState = location.state as IPaymentResp;
	// // console.log('locationState',locationState)
	// const {session, setSession} = useSessionContext();
	const navigate = useNavigate();

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Pagamento realizado</title>
			</Helmet>
			<CustomContainer sx={{py: 4}}>
				<Box mb={3}>
					<Typography variant={'h1'} color={'primary'} fontWeight={'bold'}>
						PARABÉNS!
					</Typography>
					<Typography mb={3} variant={'h3'}>
						Sua conta foi criada com sucesso!
					</Typography>
					<Typography variant={'h4'} color={'text.secondary'}>
						Agora você já possui acesso a nossa plataforma de cursos e pode adquirir qualquer treinamento nosso.
					</Typography>
					<Typography variant={'h4'} color={'text.secondary'}>
						Caso precise de ajuda basta clicar no botão do Whatsapp para falar conosco
					</Typography>
				</Box>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Button variant={'contained'} onClick={() => navigate('/login')}>
						Fazer login
					</Button>
					<Button variant={'contained'} endIcon={<RiExternalLinkFill />} href={ENV.baseUrlPlataforma}>
						Acessar a plataforma
					</Button>
				</Box>
			</CustomContainer>
		</PaymentBg>
	);
}
