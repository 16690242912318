import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import App from './App';
import theme from './theme';
import {createRoot} from 'react-dom/client';
import {SM} from './constants/ui/breakpoints';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<ThemeProvider
		theme={responsiveFontSizes(theme, {
			factor: window.innerWidth > SM ? 1 : 5,
		})}
	>
		{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
		<CssBaseline />
		<App />
	</ThemeProvider>
);
