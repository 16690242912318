import {Fab, Snackbar} from '@mui/material';
import {FaWhatsapp} from 'react-icons/fa';
import React, {FC} from 'react';
import {LINK_WHATSAPP} from '../../constants/links';

const BtnWhatsapp: FC = () => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={true}
		>
			<Fab target={'_blank'} href={LINK_WHATSAPP} color="success" size={'medium'} aria-label="whatsapp">
				<FaWhatsapp fontSize={'22px'} />
			</Fab>
		</Snackbar>
	);
};

export default BtnWhatsapp;
