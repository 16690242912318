import {Divider, useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {FaFacebook, FaInstagram, FaWhatsapp, FaYoutube} from 'react-icons/fa/index';
import {LINK_FACEBOOK, LINK_INSTAGRAM, LINK_WHATSAPP, LINK_YOUTUBE} from '../../../constants/links';
import {DARK_SECONDARY} from '../../../constants/ui/colors';
import {SP_DEFAULT} from '../../../constants/ui/spacements';
import BtnIcon from '../../BtnIcon/BtnIcon';

export default function Footer() {
	const theme = useTheme();
	// console.log(theme.breakpoints.keys)

	const socialMedias = useMediaQuery(theme.breakpoints.up('md')) ? (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-arround',
				flexFlow: 'column',
			}}
		>
			<Typography sx={{color: DARK_SECONDARY}} variant={'h5'}>
				Acompanhe-nos
			</Typography>
			<Box display={'flex'} mt={1}>
				<BtnIcon href={LINK_INSTAGRAM}>
					<FaInstagram color={DARK_SECONDARY} fontSize={23} />
				</BtnIcon>
				<BtnIcon href={LINK_YOUTUBE}>
					<FaYoutube
						color={DARK_SECONDARY}
						style={{
							margin: '0 15px',
						}}
						fontSize={23}
					/>
				</BtnIcon>
				<BtnIcon href={LINK_FACEBOOK}>
					<FaFacebook color={DARK_SECONDARY} fontSize={23} />
				</BtnIcon>
			</Box>
			<Box display={'flex'}>
				<BtnIcon href={LINK_WHATSAPP}>
					<FaWhatsapp color={DARK_SECONDARY} fontSize={23} />
				</BtnIcon>
				<Typography sx={{color: DARK_SECONDARY}} variant={'h5'}>
					{' '}
					(55) 99966-7868
				</Typography>
			</Box>
		</Box>
	) : null;

	return (
		<Box sx={{}}>
			<Divider />
			<Box
				sx={{
					p: SP_DEFAULT,
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Box>
					<img
						src="https://cdn.txaiacademia.com.br/images/logos/grupotxai_min.png"
						alt={'Logo da Txai'}
						style={{
							width: '70px',
						}}
					/>
					<Box id="googleTagManager"></Box>
				</Box>

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexFlow: 'column',
					}}
				>
					<img src={'./images/stamps/buystamp.png'} alt={'Logo da Txai'} width={'263px'} />
					<Typography sx={{color: DARK_SECONDARY}} mt={2} variant={'h5'}>
						Copyright © 2022 Txai Desenvolvimento.
					</Typography>
					<Typography sx={{color: DARK_SECONDARY}} mt={2} variant={'h5'}>
						Todos os direitos reservados.
					</Typography>
				</Box>
				{socialMedias}
			</Box>
		</Box>
	);
}
