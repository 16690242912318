/**
 * scrollToElementById scrolls the document to the element with the specified ID.
 * @param {string} elementId The ID of the element to scroll to.
 */
export const scrollToElementById = (elementId: string) => {
	const element = document.getElementById(elementId);

	if (element) {
		// Utiliza a API de scrollIntoView para rolar até o elemento de forma suave
		element.scrollIntoView({
			behavior: 'smooth', // Define o comportamento do scroll como suave
			block: 'start', // Alinha o elemento ao topo da página
		});
	} else {
		console.warn(`Element with ID '${elementId}' not found.`);
	}
};
