import {IProduct} from 'shared/interfaces/IProduct';
import {ICart} from 'shared/interfaces/iCart';

export const cartsIsEquals = (cart1: ICart, cart2: ICart) => {
	const checkProducts = Object.values(cart1.produtos).every(
		(product: IProduct) =>
			cart2.produtos[product.idProduto] &&
			product.quantidade === cart2.produtos[product.idProduto].quantidade &&
			product.valor === cart2.produtos[product.idProduto].valor
	);

	if (
		cart1.produtos.length === cart2.produtos.length &&
		cart1.total === cart2.total &&
		cart1.totalDesconto === cart2.totalDesconto &&
		cart1.idCarrinho === cart2.idCarrinho &&
		checkProducts
	) {
		return true;
	}
	return false;
};
