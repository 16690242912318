import React, {useEffect, useState} from 'react';
import {Button, Card, Grid, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import CustomContainer from '../../components/Container/CustomContainer';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import PaymentBg from '../../components/paymentBg';
import Typography from '@mui/material/Typography';
import api, {IErrorResponse} from '../../shared/api';
import {LS} from '../../shared/enums/localstorage';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {isCpf, isEmail, isPhone, maxLenght, minLenght, required} from '../../shared/helpers/reactFormHookValidation';
import AddressFields from '../../components/AddressFields/AddressFields';
import {CARD_BORDERRADIUS} from '../../constants/ui/borders';
import {FaChevronLeft} from 'react-icons/fa';
import theme from '../../theme';
import {useSessionContext} from '../../shared/contexts/sessionContext';
import {IRegisterReq, IRegisterResp} from '../../shared/interfaces/requests/register';
import {IUser} from '../../shared/interfaces/IUser';
import {Helmet} from 'react-helmet';
import ToastContainer from '../../components/ToastContainer/ToastContainer';
import {TToastState} from '../../shared/providers/ToastProvider';
import {EnumToast} from '../../shared/enums/toast';
import {clearValue, maskCellphone, maskCpf} from '../../shared/helpers/mask';
import {useBackdropState} from '../../shared/contexts/backdropContext';

interface LoginFormData {
	dadosUsuario: IUser;
}

export default function Cadastro() {
	const navigate = useNavigate();

	const location = useLocation();
	const locationState = location.state as {cpf: string; redirect?: string} | null;
	let redirect = locationState && locationState.redirect !== undefined ? decodeURIComponent(locationState.redirect) : null;
	const [searchParams] = useSearchParams();

	let token = localStorage.getItem(LS.CARTTOKEN);

	if (!redirect) {
		redirect = searchParams.get('redirect');
		if (redirect) {
			localStorage.setItem(LS.REDIRECT, redirect);
		}
	}

	useEffect(() => {
		if (token) {
			if (localStorage.getItem(LS.CARTTOKEN) && session.session !== undefined) {
				setSession({
					cart: undefined,
					session: undefined,
				});
			}

			localStorage.setItem(LS.CARTTOKEN, token);
		} else {
			let tokenCart = searchParams.get('tokenCart');

			if (tokenCart) {
				localStorage.setItem(LS.CARTTOKEN, tokenCart);
			} else {
				const redirect = localStorage.getItem(LS.REDIRECT);

				if (redirect) {
					window.location.href = redirect;
				} else {
					navigate('/login');
				}
			}
		}

		if (!token) {
			navigate('/login');
		}
	}, [token]);

	const {setBackdropVisibility} = useBackdropState();

	const [toast, setToast] = useState<TToastState>(null);

	const cpf = locationState !== null && locationState.cpf !== undefined ? locationState.cpf : '';

	const ctrls = useForm<LoginFormData>({
		shouldFocusError: true,
		defaultValues: {
			dadosUsuario: {
				email: '',
				cpf: maskCpf(cpf),
				senha: '',
				nome: '',
				telefone: '',
				bairro: '',
				numero: '',
				endereco: '',
				complemento: '',
				cidade: '',
				cep: '',
			},
		},
		// resolver: yupResolver(schema),
	});

	const {session, setSession} = useSessionContext();

	const onSubmit = ({dadosUsuario}: LoginFormData) => {
		// console.log(dadosUsuario);

		setBackdropVisibility(true);

		api
			.post<IRegisterReq, IRegisterResp | null>('subscribe', {
				token: token as string,
				user: {
					...dadosUsuario,
					cpf: clearValue(dadosUsuario.cpf),
					cep: clearValue(dadosUsuario.cep),
					telefone: clearValue(dadosUsuario.telefone),
				} as IUser,
			})
			.then(({data}) => {
				if (data.success && data.data) {
					setSession({
						cart: data.data.carrinho,
						session: {
							user: {
								nome: data.data.user.name,
								email: data.data.user.email,
								cpf: clearValue(dadosUsuario.cpf),
							},
							token: data.data.token,
						},
					});

					if (redirect) {
						if (redirect.includes('http')) {
							window.location.href = redirect;
						} else {
							navigate(redirect);
						}
					} else {
						navigate('/pagamento');
					}
				} else {
					setToast({
						type: EnumToast.ERROR,
						text: data.message,
					});
				}
			})
			.catch(({response: {data}}: IErrorResponse) => {
				// console.log(data)
				setToast({
					type: EnumToast.ERROR,
					text: data.message,
				});
			})
			.finally(() => {
				setBackdropVisibility(false);
			});
	};

	const {
		setValue,
		control,
		getValues,
		formState: {errors},
	} = ctrls;

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Cadastre-se para adquirir nossos produtos</title>
			</Helmet>
			<CustomContainer sx={{pt: 6, pb: 6 * 2}}>
				<Typography fontWeight={'bold'} variant={'h1'} mb={2}>
					Novo por aqui?
				</Typography>
				<Typography variant={'h4'} mb={2}>
					Seja bem-vindo(a)! <br />
					Notamos que você não possui cadastro. Por favor, insira suas informações nos campos abaixo para continuar:
				</Typography>
				<ToastContainer toast={toast} setToast={setToast} />

				<Card
					variant="elevation"
					elevation={1}
					sx={theme => ({
						p: 6,
						borderRadius: CARD_BORDERRADIUS,
						[theme.breakpoints.down('md')]: {
							p: 2,
						},
					})}
				>
					<FormProvider {...ctrls}>
						<form onSubmit={ctrls.handleSubmit(onSubmit)}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Controller
										control={control}
										name="dadosUsuario.cpf"
										rules={{
											...isCpf,
											required: required(),
										}}
										render={({field}) => (
											<TextField
												fullWidth
												size={'small'}
												disabled={cpf.length >= 1}
												id="cpf"
												{...field}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													setValue('dadosUsuario.cpf', maskCpf(e.target.value));
												}}
												error={Boolean(errors.dadosUsuario?.cpf)}
												helperText={errors.dadosUsuario?.cpf && `O CPF digitado é inválido `}
												label="CPF - Somente números"
												sx={{backgroundColor: 'white'}}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										control={control}
										name="dadosUsuario.nome"
										rules={{
											minLength: minLenght(2),
											maxLength: maxLenght(255, 'alguns'),
											required: required(),
										}}
										render={({field}) => (
											<TextField
												fullWidth
												size={'small'}
												label={'Nome Completo'}
												error={Boolean(errors.dadosUsuario?.nome)}
												helperText={errors.dadosUsuario?.nome?.message}
												{...field}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										control={control}
										name="dadosUsuario.email"
										rules={{...isEmail, required: required()}}
										render={({field}) => (
											<TextField
												fullWidth
												size={'small'}
												label={'E-mail'}
												error={Boolean(errors.dadosUsuario?.email)}
												helperText={errors.dadosUsuario?.email?.message}
												{...field}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										control={control}
										name="dadosUsuario.senha"
										rules={{
											minLength: minLenght(8),
											maxLength: maxLenght(255, 'alguns'),
											required: required(),
										}}
										render={({field}) => (
											<TextField
												fullWidth
												size={'small'}
												type={'password'}
												label={'Senha'}
												error={Boolean(errors.dadosUsuario?.senha)}
												helperText={errors.dadosUsuario?.senha?.message}
												{...field}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Controller
										control={control}
										name="dadosUsuario.telefone"
										rules={{
											...isPhone,
											required: required(),
										}}
										render={({field}) => (
											<TextField
												fullWidth
												size={'small'}
												label="Whatsapp"
												error={Boolean(errors.dadosUsuario?.telefone)}
												helperText={errors.dadosUsuario?.telefone?.message}
												{...field}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													const cellphone = clearValue(e.target.value);
													setValue(`${'dadosUsuario'}.telefone`, maskCellphone(cellphone));
												}}
											/>
										)}
									/>
								</Grid>
								<AddressFields fieldName={'dadosUsuario'} />

								<Grid item xs={12}>
									<Box display={'flex'} mt={2} justifyContent={'space-between'}>
										<Button
											hidden={true}
											variant="text"
											startIcon={<FaChevronLeft color={theme.palette.primary.main} />}
											type="button"
											onClick={() => navigate('/login')}
										>
											<Typography variant={'h4'} color={'text.primary'}>
												Voltar
											</Typography>
										</Button>
										<Button variant="contained" size={'medium'} type="submit">
											Avançar
										</Button>
									</Box>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</Card>
			</CustomContainer>
		</PaymentBg>
	);
}
