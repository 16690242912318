import {Controller, useFormContext} from 'react-hook-form';
import {FormControl, FormHelperText, MenuItem, Select} from '@mui/material';
import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {ICoursePayment} from '../../../../shared/interfaces/ICoursePayment';
import Typography from '@mui/material/Typography';

interface IProps {
	products: ICoursePayment[];
}

const CoursesDisponibles: FC<IProps> = ({products}) => {
	const ctrls = useFormContext();
	const {
		register,
		getValues,
		handleSubmit,
		control,
		formState: {errors},
		setValue,
		unregister,
	} = ctrls;

	return (
		<Box>
			<Typography variant={'h4'}>Curso</Typography>
			<Box mt={2}>
				<Controller
					name="product"
					control={control}
					defaultValue={0}
					rules={{
						required: {
							message: 'Obrigatório',
							value: true,
						},
						min: {
							message: 'Selecione um curso válido',
							value: 1,
						},
					}}
					render={({field}) => (
						<FormControl fullWidth error={Boolean(errors.product)}>
							<Select
								fullWidth
								size={'small'}
								label=""
								{...field}
								// onChange={e => {setValue('dadosNotaFiscal.tipoPessoa', 2)}}
							>
								<MenuItem value={0}>-- Selecione --</MenuItem>
								{products?.map(prod => (
									<MenuItem key={prod.idPagamentoCurso} value={prod.idPagamentoCurso}>
										{prod.dadosProduto.nome}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>{errors.product ? `Selecione ao menos um curso` : ''}</FormHelperText>
						</FormControl>
					)}
				/>
			</Box>
		</Box>
	);
};

export default CoursesDisponibles;
