import Typography from '@mui/material/Typography';
import React from 'react';

export default function TextSecondary({children, sx}: {children: any; sx?: any}) {
	return (
		<Typography sx={{...sx}} fontWeight={'bold'}>
			{children}
		</Typography>
	);
}
