import {Alert} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export const CartWithOutProductsMessage = () => {
	return (
		<Box mt={4}>
			<Alert severity={'warning'} sx={{width: '100%', mt: 1}}>
				<Typography variant={'h4'}>Você deve possuir ao menos um produto em seu carrinho para prosseguir</Typography>
			</Alert>
		</Box>
	);
};
