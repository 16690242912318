import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from 'react';
import {ISession} from '../interfaces/iSession';
import {LS} from '../enums/localstorage';

interface IStateControl {
	session: ISession;
	setSession: Dispatch<SetStateAction<ISession>>;
}

////////////

const defaultState: ISession = {
	cart: undefined,
	session: undefined,
};

////////////

const SessionContext = createContext<IStateControl | undefined>(undefined);

const SessionContextProvider = ({children}: any) => {
	let sessionState = defaultState;

	const [session, setSession] = useState<ISession>(sessionState);

	// console.log('Renderizou contexto da sessao')

	const _setSession = (session: ISession) => {
		localStorage.setItem(LS.USER_SESSION, JSON.stringify(session));
		return setSession(session);
	};

	const contextValue = useMemo(
		() => ({
			session,
			setSession: _setSession as Dispatch<SetStateAction<ISession>>,
		}),
		[session, setSession]
	);

	useEffect(() => {
		if (session === undefined) {
			throw Error('Erro ao definir a sessão');
		} else if (session.session === undefined) {
			const localSession = localStorage.getItem(LS.USER_SESSION);
			if (localSession) {
				setSession(JSON.parse(localSession) as ISession);
			}
		}
	}, [session.session]);

	return <SessionContext.Provider value={contextValue!}>{children}</SessionContext.Provider>;
};

function useSessionContext() {
	let context = useContext(SessionContext);

	if (context === undefined) {
		throw Error('Contexto Não definido');
	}

	return context;
}

export {useSessionContext, SessionContextProvider};
