import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {Helmet} from 'react-helmet';
import {RiExternalLinkFill} from 'react-icons/ri';
import {useLocation, useNavigate} from 'react-router-dom';
import ENV from 'shared/configs/environment';
import CardPayment from '../../components/CardPayment/CardPayment';
import CustomContainer from '../../components/Container/CustomContainer';
import PaymentBg from '../../components/paymentBg';
import {PAYMENT_OPTIONS, PAYMENT_STATUS} from '../../constants/database_ids';
import {useSessionContext} from '../../shared/contexts/sessionContext';
import {IPaymentResp} from '../../shared/interfaces/requests/payment';
import CompraEmAguardoBoleto from './sections/CompraEmAguardoBoleto';
import SecMaisDeUm from './sections/MaisDeUm';

export default function Concluir() {
	const location = useLocation();
	const navigate = useNavigate();

	const {idTipoPagamento, emailsResult, extraData} = location.state as IPaymentResp;

  const { session } = useSessionContext();

	if (!session.session) {
		navigate('/login');
	}

	const cpf = session.session!.user.cpf;

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Pagamento realizado</title>
			</Helmet>
			<CustomContainer sx={{py: 4}}>
				<CardPayment>
					{idTipoPagamento === PAYMENT_OPTIONS.BILLET ? (
						<CompraEmAguardoBoleto numeroBoleto={extraData.numeroBoleto!} codigoBarras={extraData.codigoBarraBoleto!} />
					) : emailsResult.status === PAYMENT_STATUS.REALIZADA ? (
						CompraFinalizada
					) : (
						CompraEmAguardo
					)}
					{emailsResult.maisDeUm ? <SecMaisDeUm userDocument={cpf} /> : null}
				</CardPayment>
			</CustomContainer>
		</PaymentBg>
	);
}

const CompraFinalizada = (
	<Box mb={3}>
		<Typography variant={'h1'} color={'primary'} fontWeight={'bold'}>
			PARABÉNS!
		</Typography>
		<Typography mb={3} variant={'h3'}>
			Compra finalizada com sucesso!
		</Typography>
		<Typography variant={'h4'} color={'text.secondary'}>
			Verifique seu e-mail com todas as informações sobre sua compra.
		</Typography>

		<Typography variant={'h4'} color={'text.secondary'}>
			Você pode acessar nossa plataforma através do botão abaixo. Recomendamos salvá-lo nos seus favoritos.
		</Typography>

		<Box display={'flex'} mb={2} mt={2}>
			<Button
				variant={'contained'}
				size={'large'}
				sx={{
					borderWidth: '1px',
					minInlineSize: 'max-content',
				}}
				target={'_blank'}
				href={ENV.baseUrlPlataforma}
				color={'primary'}
				endIcon={<RiExternalLinkFill />}
			>
				Acessar plataforma da Txai Academia
			</Button>
		</Box>

		<Typography variant={'h4'} color={'text.secondary'}>
			Caso precise de ajuda basta clicar no botão do Whatsapp para falar conosco
		</Typography>
	</Box>
);

const CompraEmAguardo = (
	<Box mb={3}>
		<Typography variant={'h1'} color={'primary'} fontWeight={'bold'}>
			PARABÉNS!
		</Typography>
		<Typography mb={3} variant={'h3'}>
			Sua solicitação de pagamento foi realizada com sucesso!
		</Typography>
		<Typography variant={'h4'} color={'text.secondary'}>
			Assim que confirmarmos seu pagamento você receberá um e-mail avisando.
		</Typography>
		<Typography variant={'h4'} color={'text.secondary'}>
			Verifique seu e-mail com todas as informações sobre sua compra.
		</Typography>
		<Typography variant={'h4'} color={'text.secondary'}>
			Caso precise de ajuda basta clicar no botão do Whatsapp para falar conosco
		</Typography>
	</Box>
);
