import {useMediaQuery, useTheme} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React from 'react';
import {STEPS, STEPSMOBILE} from '..';

interface IStepsProps {
	activeStep: number;
}

export default function Steps({activeStep}: IStepsProps) {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Stepper activeStep={activeStep}>
			{[...(isMobile ? STEPSMOBILE : STEPS)].map((label, index) => {
				const stepProps: {completed?: boolean} = {};
				const labelProps: {
					optional?: React.ReactNode;
				} = {};

				return isMobile ? (
					<Step key={index} {...stepProps}>
						<StepLabel {...labelProps}></StepLabel>
						<Typography fontWeight={index === activeStep ? 'bold' : 'normal'} mt={1} variant={'h5'}>
							{label}
						</Typography>
					</Step>
				) : (
					<Step key={index} {...stepProps}>
						<StepLabel {...labelProps}>
							<Typography fontWeight={index === activeStep ? 'bold' : 'normal'} variant={'h4'}>
								{label}
							</Typography>
						</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	);
}
