import {Button, Grid, Skeleton} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {Suspense} from 'react';
import {Helmet} from 'react-helmet';
import {useImage} from 'react-image';
import CustomContainer from '../../components/Container/CustomContainer';
import PaymentBg from '../../components/paymentBg';
import {LINK_WHATSAPP} from '../../constants/links';

export default function ApplicationError() {
	const {src} = useImage({
		srcList: '/images/errors/notFound.png',
	});

	return (
		<PaymentBg>
			<Helmet>
				<meta name="description" content="Txai Pagamentos. Faça seu pagamento seguro em um de nossos produtos" />
				<title>Realize seu pagamento com segurança</title>
			</Helmet>
			<CustomContainer sx={{height: '100%'}}>
				<Grid container sx={{mt: '10vh'}} spacing={4} flexWrap={'wrap-reverse'}>
					<Grid item xs={12} md={5} sm={6}>
						<Typography fontWeight={'bold'} sx={{mt: -3}} fontSize={100}>
							404
						</Typography>
						<Typography fontWeight={'bold'} sx={{mt: -2}} variant={'h1'}>
							Ooops!
						</Typography>
						<Typography fontWeight={'bold'} mt={-1} variant={'h2'}>
							Ocorreu um erro inesperado
						</Typography>
						<Typography mt={2} variant={'h4'}>
							Parece que e página que está tentando acessar não está disponível no momento. Sugerimos que volte para a tela inicial ou entre em
							contato com o suporte.
						</Typography>
						<Box mt={1}>
							<Button sx={{mt: 2}} href={'/login'} variant={'contained'}>
								Voltar a página inicial
							</Button>
							<Button sx={{ml: 2, mt: 2}} href={LINK_WHATSAPP} target={'_blank'} variant={'outlined'}>
								Suporte
							</Button>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={7}
						sm={5}
						sx={theme => ({
							[theme.breakpoints.down('sm')]: {
								display: 'none',
							},
						})}
					>
						<Suspense fallback={<Skeleton variant={'rectangular'} width={210} height={118} />}>
							<img width={'100%'} src={src} alt={'Página não encontrada'} />
						</Suspense>
					</Grid>
				</Grid>
			</CustomContainer>
		</PaymentBg>
	);
}
