import Box from '@mui/material/Box';
import React, {useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {EnumToast} from '../../shared/enums/toast';
import {TToastState} from '../../shared/providers/ToastProvider';
import ToastContainer from '../ToastContainer/ToastContainer';

interface IProps {
	children: React.ReactNode;
	text: string;
	successMessage?: string;
	toastDuration?: number;
}

const BtnCopy: React.FC<IProps> = props => {
	const [toast, setToast] = useState<TToastState>(null);

	const handleCopy = (text: string, result: boolean) => {
		setToast(
			result
				? {
						type: EnumToast.SUCCESS,
						text: props.successMessage ?? 'Código copiado com sucesso',
					}
				: {
						type: EnumToast.ERROR,
						text: `Tivemos um problema ao copiar o seguinte texto: ${text}`,
					}
		);
	};

	return (
		<Box sx={{position: 'relative', zIndex: '2'}}>
			<CopyToClipboard text={props.text} onCopy={handleCopy}>
				{props.children}
			</CopyToClipboard>
			<ToastContainer duration={props.toastDuration} toast={toast} setToast={setToast} />
		</Box>
	);
};

export default BtnCopy;
