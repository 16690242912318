import {ENV_DEVELOPMENT, ENV_PRODUCTION} from '../../constants/appconfig';

interface ISystemaVariables {
	readonly baseUrl: string;
	readonly baseUrlPagamentos: string;
	readonly baseUrlDiscordBot: string;
	readonly baseUrlPlataforma: string;
}

export type T_ENV = typeof ENV_DEVELOPMENT | typeof ENV_PRODUCTION;

class Environment implements ISystemaVariables {
	readonly env: string;
	readonly baseUrl: string;
	readonly baseUrlPagamentos: string;
	readonly baseUrlPlataforma: string;
	readonly baseUrlDiscordBot: string;

	constructor() {
		this.baseUrlDiscordBot = process.env.REACT_APP_BASEURL_DISCORDBOT_WEBSERVICE as string;
		this.env = process.env.REACT_APP_ENVIRONMENT as T_ENV;
		try {
			this.baseUrl = process.env.REACT_APP_BASEURL as string;
			this.baseUrlPagamentos = process.env.REACT_APP_BASEURL_PAGAMENTOS as string;
			this.baseUrlPlataforma = process.env.REACT_APP_BASEURL_PLATAFORMA as string;
		} catch (e) {
			this.baseUrl = '';
			this.baseUrlPagamentos = '';
			this.baseUrlPlataforma = '';
			new Error(`O ambiente definido é inexistente: ${this.env}`);
		}
	}
}

const ENV = new Environment();
export default ENV;
