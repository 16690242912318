import {Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {BLUE_PRIMARY} from 'constants/ui/colors';
import React, {useEffect, useState} from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {RiFileCopyLine} from 'react-icons/ri';
import BtnCopy from '../../../../components/BtnCopy/BtnCopy';
import {PAYMENT_OPTIONS} from '../../../../constants/database_ids';
import {useSessionContext} from '../../../../shared/contexts/sessionContext';
import {generateCopiaCola} from '../../../../shared/helpers/qrcode';
import CardInfo from './cardInfo';

interface IProps {}
export const PROD_MASTERMIND = 22;
export const PROD_MASTERMIND_VALUE = 60150;

export default function PaymentsMethods({}: IProps) {
	const ctrls = useFormContext();

	const {
		register,
		getValues,
		handleSubmit,
		control,
		formState: {errors},
		setValue,
		unregister,
	} = ctrls;

	const {session, setSession} = useSessionContext();

	const [pixCode, setPixCode] = useState(
		generateCopiaCola(
			session.cart!.produtos[PROD_MASTERMIND] !== undefined
				? session.cart!.produtos[PROD_MASTERMIND].quantidade * PROD_MASTERMIND_VALUE
				: session.cart!.total
		)
	);
	const [qrcode, setQrCode] = useState('');
	// console.log('pixCode',pixCode)

	// useMemo(() => {

	// }, [pixCode])

	useEffect(() => {
		pixCode.toQRCode('svg').then((data: any) => {
			setQrCode(data);
		});
	}, [session.cart!.total]);

	useWatch({
		control: control,
		name: 'tipoPagamento',
	});

	const handleChange = (paymentOption: PAYMENT_OPTIONS) => {
		setValue('tipoPagamento', paymentOption);
	};

	const PrimeiroCartao = () => <CardInfo index={'dadosCartao1'} />;
	const SegundoCartao = () => <CardInfo index={'dadosCartao2'} />;

	return (
		<Box>
			<Controller
				control={control}
				name={`tipoPagamento`}
				rules={{
					required: {
						value: true,
						message: 'Selecione ao menos um método de pagamento',
					},
				}}
				render={({field}) => (
					<RadioGroup
						{...field}
						onChange={e => {
							setValue('tipoPagamento', parseInt(e.target.value) as PAYMENT_OPTIONS);
						}}
					>
						<Accordion
							sx={{mt: '0px'}}
							variant={'outlined'}
							expanded={parseInt(getValues('tipoPagamento')) === PAYMENT_OPTIONS.CREDIT}
							onChange={() => handleChange(PAYMENT_OPTIONS.CREDIT)}
						>
							<AccordionSummary>
								<Box sx={{width: '100%'}} display={'flex'} justifyContent={'space-between'}>
									<Box>
										<FormControlLabel
											value={PAYMENT_OPTIONS.CREDIT}
											control={<Radio />}
											label={
												<Typography sx={{color: 'text.secondary'}} variant={'h4'}>
													Cartão de crédito
												</Typography>
											}
										/>
									</Box>
									<Box display={'flex'}>
										<img
											style={{margin: 'auto 0'}}
											width={'150px'}
											src={'./images/paymentOptions/cartoesbandeiras.svg'}
											alt={'Bandeiras de exemplos de cartões disponíveis'}
										/>
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>{getValues('tipoPagamento') === PAYMENT_OPTIONS.CREDIT ? <PrimeiroCartao /> : null}</AccordionDetails>
						</Accordion>

						<Accordion
							sx={{mt: '0px'}}
							variant={'outlined'}
							expanded={parseInt(getValues('tipoPagamento')) === PAYMENT_OPTIONS.CREDIT_TWO_CARDS}
							onChange={() => handleChange(PAYMENT_OPTIONS.CREDIT_TWO_CARDS)}
						>
							<AccordionSummary>
								<Box sx={{width: '100%'}} display={'flex'} justifyContent={'space-between'}>
									<Box>
										<FormControlLabel
											value={PAYMENT_OPTIONS.CREDIT_TWO_CARDS}
											control={<Radio />}
											label={
												<Typography sx={{color: 'text.secondary'}} variant={'h4'}>
													Pagar com dois cartões
												</Typography>
											}
										/>
									</Box>
									<Box display={'flex'}>
										<img style={{margin: 'auto 0'}} width={'30px'} src={'./images/paymentOptions/2cards.svg'} alt={'Pagamento com dois cartões'} />
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								{getValues('tipoPagamento') === PAYMENT_OPTIONS.CREDIT_TWO_CARDS ? (
									<>
										<Box>
											<PrimeiroCartao />
										</Box>
										<Box mt={6}>
											<SegundoCartao />
										</Box>
									</>
								) : null}
							</AccordionDetails>
						</Accordion>

						<Accordion
							variant={'outlined'}
							expanded={parseInt(getValues('tipoPagamento')) === PAYMENT_OPTIONS.PIX}
							onChange={() => handleChange(PAYMENT_OPTIONS.PIX)}
						>
							<AccordionSummary>
								<Box sx={{width: '100%'}} display={'flex'} justifyContent={'space-between'}>
									<Box>
										<FormControlLabel
											value={PAYMENT_OPTIONS.PIX}
											control={<Radio />}
											label={
												<Typography sx={{color: 'text.secondary'}} variant={'h4'}>
													Pix
												</Typography>
											}
										/>
									</Box>
									<Box display={'flex'} alignItems={'center'}>
										<Typography mr={2} sx={{color: BLUE_PRIMARY}} variant={'h5'}>
											{session.cart!.produtos[PROD_MASTERMIND] !== undefined ? '28% DE DESCONTO' : null}
										</Typography>
										<img style={{margin: 'auto 0'}} width={'30px'} src={'./images/paymentOptions/pix.svg'} alt={'Pagamento com PIX'} />
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<Box sx={{margin: '0px auto', width: 'max-content'}}>
									<Box sx={{width: '150px', marginLeft: -1, marginX: 'auto'}}>
										<div dangerouslySetInnerHTML={{__html: qrcode}} />
									</Box>
									<Box>
										<BtnCopy text={pixCode.raw}>
											<Button
												variant={'outlined'}
												sx={{
													borderWidth: '1px',
													minInlineSize: 'max-content',
												}}
												color={'primary'}
												startIcon={<RiFileCopyLine />}
											>
												<Typography variant={'h4'} color={'primary'}>
													Pix copia e cola
												</Typography>
											</Button>
										</BtnCopy>
									</Box>
								</Box>

								<Box mt={2}>
									<Typography fontWeight={'bold'} color={'primary'} variant={'h4'}>
										ATENÇÃO!
									</Typography>
									<Typography fontWeight={'bold'} my={1} color={'primary'} variant={'h4'}>
										Após realizar o PIX, confirme sua compra.
									</Typography>
									<Typography variant={'h5'}>
										Com o pagamento realizado você receberá um email de confirmação assim que identificarmos seu pagamento. Não esqueça de conferir os
										dados antes de efetuar a operação pelo Pix.
									</Typography>
								</Box>
							</AccordionDetails>
						</Accordion>

						{/* <Accordion
							variant={'outlined'}
							expanded={parseInt(getValues('tipoPagamento')) === PAYMENT_OPTIONS.BILLET}
							onChange={() => handleChange(PAYMENT_OPTIONS.BILLET)}
						>
							<AccordionSummary>
								<Box sx={{width: '100%'}} display={'flex'} justifyContent={'space-between'}>
									<Box>
										<FormControlLabel
											value={PAYMENT_OPTIONS.BILLET}
											control={<Radio />}
											label={
												<Typography sx={{color: 'text.secondary'}} variant={'h4'}>
													Boleto
												</Typography>
											}
										/>
									</Box>
									<Box display={'flex'}>
										<img style={{margin: 'auto 0'}} width={'30px'} src={'./images/paymentOptions/card-billet.svg'} alt={'Pagamento com PIX'} />
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<Box mt={2}>
									<Typography fontWeight={'bold'} my={1} color={'primary'} variant={'h4'}>
										Clique em Realizar pagamento para visualizar seu boleto.
									</Typography>
									<Typography variant={'h5'}>O boleto também será enviado a você por email.</Typography>
									<Typography variant={'h5'}>Você terá 1 dia a partir de hoje para realizar o pagamento.</Typography>
									<Typography variant={'h5'}>O pagamento pode levar até 3 dias úteis para ser confirmado.</Typography>
									<Typography variant={'h5'}>Você receberá um email quando confirmarmos seu pagamento.</Typography>
								</Box>
							</AccordionDetails>
						</Accordion> */}

						<Typography variant={'h5'} mt={1} color={'error'}>
							{`${errors.tipoPagamento?.message ?? ''}`}
						</Typography>
					</RadioGroup>
				)}
			/>
		</Box>
	);
}
