import React, {createContext, Dispatch, SetStateAction, useContext, useMemo, useState} from 'react';
import {IParcel} from '../interfaces/IParcel';

interface IPaymentData {
	parcels: IParcel[];
}

interface IPaymentDataControl {
	paymentData: IPaymentData;
	setPaymentData: Dispatch<SetStateAction<IPaymentData>>;
}

const DEFAULT_STATE: IPaymentData = {
	parcels: [],
};

const PaymentDataContext = createContext<IPaymentDataControl | null>(null);

const PaymentDataContextProvider = ({children}: any) => {
	const [paymentData, setPaymentData] = useState<IPaymentData>(DEFAULT_STATE);

	const contextValue = useMemo(
		() => ({
			paymentData,
			setPaymentData,
		}),
		[paymentData]
	);

	return <PaymentDataContext.Provider value={contextValue!}>{children}</PaymentDataContext.Provider>;
};

function usePaymentDataState() {
	let context = useContext(PaymentDataContext);

	if (context === null) {
		throw Error('Contexto Não definido');
	}

	return context;
}

export {usePaymentDataState, PaymentDataContextProvider};
