import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {RiExternalLinkFill} from 'react-icons/ri';
import {generateBilletLink} from 'shared/helpers/billet';

interface IParams {
	numeroBoleto: string;
	codigoBarras: string;
}

export default function CompraEmAguardoBoleto({numeroBoleto, codigoBarras}: IParams) {
	return (
		<Box mb={3} textAlign={'center'}>
			<Typography variant={'h1'} color={'primary'} fontWeight={'bold'}>
				Seu boleto foi gerado com sucesso!
			</Typography>

			<Typography mb={3} variant={'h3'}>
				Realize o pagamento acessando o boleto através do botão abaixo
			</Typography>
			<Box display={'flex'} justifyContent="center" mb={3}>
				<Button
					variant={'contained'}
					size={'large'}
					sx={{
						borderWidth: '1px',
						minInlineSize: 'max-content',
					}}
					target={'_blank'}
					href={generateBilletLink(numeroBoleto)}
					color={'primary'}
					endIcon={<RiExternalLinkFill />}
				>
					Acessar boleto
				</Button>
			</Box>

			{/* <Typography mb={3} variant={"h3"}>
        Ou copiando o código de barras diretamente
      </Typography>

      <Box mb={3}>
        <Box mb={3} marginX={"auto"} width={"max-content"}>
          <BtnCopy
            text={codigoBarras}
            successMessage={"Código de barras copiado"}
          >
            <Button
              variant={"outlined"}
              sx={{
                borderWidth: "1px",
                minInlineSize: "max-content",
              }}
              color={"primary"}
              endIcon={<RiFileCopyLine />}
            >
              <Typography variant={"h4"} color={"primary"}>
                {codigoBarras}
              </Typography>
            </Button>
          </BtnCopy>
        </Box>
      </Box> */}

			<Typography variant={'h4'} color={'text.secondary'}>
				Assim que confirmarmos seu pagamento você receberá um e-mail liberando seu acesso.
			</Typography>
			<Typography variant={'h4'} color={'text.secondary'}>
				Você receberá um e-mail com o boleto e as informações da sua compra.
			</Typography>
			<Typography variant={'h4'} color={'text.secondary'}>
				Caso precise de ajuda basta clicar no botão do Whatsapp para falar conosco.
			</Typography>
		</Box>
	);
}
