import * as React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {BrowserRouter} from 'react-router-dom';
import Layout from './components/layout';
import ApplicationError from './pages/errors/applicationError';
import discordApi from './shared/api/discordbot';
import ENV from './shared/configs/environment';
import {BackdropContextProvider} from './shared/contexts/backdropContext';
import {SessionContextProvider} from './shared/contexts/sessionContext';
import {ToastConsumer} from './shared/providers/ToastProvider';

export default function App() {
	const handleError = (error: Error, info: {componentStack: string}) => {
		// const navigate = useNavigate();

		// if (ENV.env === ENV_PRODUCTION){
		discordApi
			.post('alert', {
				system: 3,
				urlRequested: ENV.baseUrl,
				message: error.message,
				responseCode: 0,
				extraData: {
					...error,
					componentStack: info.componentStack,
				},
			})
			.finally(() => {
				// navigate('/login')
			});
		// }
	};

	return (
		<ErrorBoundary FallbackComponent={ApplicationError} onError={handleError}>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY as string}
				language="pt-BR"
				scriptProps={{
					async: false, // optional, default to false,
					defer: false, // optional, default to false
					appendTo: 'head', // optional, default to "head", can be "head" or "body",
					nonce: undefined, // optional, default undefined
				}}
				container={{
					element: 'googleTagManager',
					parameters: {
						badge: 'bottomleft', // optional, default undefined
					},
				}}
			>
				<SessionContextProvider>
					<BrowserRouter>
						<ToastConsumer>
							<BackdropContextProvider>
								<Layout />
							</BackdropContextProvider>
						</ToastConsumer>
					</BrowserRouter>
				</SessionContextProvider>
			</GoogleReCaptchaProvider>
		</ErrorBoundary>
	);
}
