export enum PAYMENT_OPTIONS {
	CREDIT = 1,
	CREDIT_TWO_CARDS = 10,
	PIX = 5,
	BILLET = 3,
}

export enum PAYMENT_STATUS {
	AGUARDANDO = 1,
	REALIZADA = 2,
}
